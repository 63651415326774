import * as React from "react";

import CIcon from "@coreui/icons-react";
import {
  cilVolumeHigh,
  cilTrash,
  cilPencil,
  cilText,
  cilMediaPlay,
  cilMediaStop,
  cilEnvelopeClosed,
} from "@coreui/icons";
import BenzAMRRecorder from "benz-amr-recorder";

//component
import { IconButton } from "../Atomics";
import confirmationDialog from "../toast-dialogs/ConfirmationDialog";
import { TemplateContent } from "../messages";

//services
import {
  deleteMessageTemplate,
  getUdioUrlByFilePath,
} from "../../services/messagesService";
import HandleUIError from "../../utils/HandleUIError";
import {useEffect} from "react";
import usePermission from "../../hooks/usePermission";
import {createActivityReq} from "../../services/activityLogService";

interface Props {
  name: string | null;
  id?: number;
  refreshTemplates: () => Promise<void>;
  isText?: boolean;
  isEmail?: boolean;
  isAudio?: boolean;
  text: string;
  title: string;
  category?: string;
  filePath: string | null;
}

const PreStoredMessagesItem: React.FC<Props> = ({
  name,
  id,
  refreshTemplates,
  isText,
  isAudio,
  isEmail,
  text,
  category,
  filePath,
  title,
}) => {
  const { checkPermission } = usePermission()
  const [isLoadingDelete, setIsLoadingDelete] = React.useState(false);
  const [showContent, setShowContent] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [loadingPlayAudio, setLoadingPlayAudio] = React.useState(false);
  const isCustom = category === "custom";
  const [currentAudio, setCurrentAudio] =
    React.useState<any>(null);

  function closeModalContent() {
    setShowContent(false);
  }
  function openEditModal() {
    setShowContent(true);
    setEditMode(true);
  }

  async function deleteTemplate() {
    if (id) {
      setIsLoadingDelete(true);
      try {
        await deleteMessageTemplate(id);
        await refreshTemplates();
        setIsLoadingDelete(false);
        await createActivityReq('Pre-Stored Messages Removed Successfully')
      } catch (e) {
        setIsLoadingDelete(false);
        HandleUIError(e);
      }
    }
  }

  function onPressDelete() {
    confirmationDialog({
      title: "Are you sure you want to delete this template?",
      confirmAction: deleteTemplate,
    });
  }

  async function onPressPlay() {
    setLoadingPlayAudio(true);
    try {
      if (filePath) {
        console.log('filePath', filePath)
        // const audioData = await getUdioUrlByFilePath(filePath);
        // const amr = new BenzAMRRecorder();
        // await amr.initWithUrl(audioData.audioUrl);
        // amr.play();
        setLoadingPlayAudio(false);
        let audio: any = new Audio(`${filePath}`);
        setCurrentAudio(audio);
        audio.play();
      }
    } catch (e) {
      setLoadingPlayAudio(false);
      HandleUIError(e);
    }
  }

  function onPressStop() {
    currentAudio?.pause();
    setCurrentAudio(null);
  }

  useEffect(() => {
      const handleAudioEnded = () => {
        setCurrentAudio(null);
      };

    // Attach the ended event listener
    currentAudio && currentAudio.addEventListener('ended', handleAudioEnded);

    // Cleanup the event listener on component unmount
    return () => {
      currentAudio && currentAudio.removeEventListener('ended', handleAudioEnded);
    };

  }, [currentAudio]);

  //runs when the audio finishes playing
  // currentAudio?.ended
  // onEnded(() => setCurrentAudio(null));

  return (
    <>
      <div className="d-flex my-3 w-100 justify-content-between justify-content-sm-start px-4">
        <div className="d-flex w-50 justify-content-between">
          <div className="message-title d-flex align-items-center w-100 ">
            <p className="ms-2 text-light">{name}</p>
          </div>
          <div className="">
            {isText && (
              <CIcon icon={cilText} size="lg" className="ms-1 messages-icon" />
            )}
            {isAudio && (
              <CIcon
                icon={cilVolumeHigh}
                size="lg"
                className="ms-1 messages-icon"
              />
            )}
            {isEmail && (
              <CIcon
                icon={cilEnvelopeClosed}
                size="lg"
                className="ms-1 messages-icon"
              />
            )}
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end pe-2 w-50">
          {isAudio && (
            <>
              {!currentAudio ? (
                <IconButton
                  icon={cilMediaPlay}
                  size="lg"
                  className="me-2 edit-template-icon mt-1"
                  onClick={onPressPlay}
                  isLoading={loadingPlayAudio}
                />
              ) : (
                <IconButton
                  icon={cilMediaStop}
                  size="lg"
                  className="me-2 edit-template-icon mt-1"
                  onClick={onPressStop}
                />
              )}
            </>
          )}
          {checkPermission("Create and Edit Message") && (
              <IconButton
                  icon={cilPencil}
                  size="lg"
                  className="me-2 edit-template-icon mt-1"
                  onClick={openEditModal}
              />
          )}
          {checkPermission("Delete Message") && (
              <>
                {isCustom && (
                    <IconButton
                        icon={cilTrash}
                        onClick={onPressDelete}
                        isLoading={isLoadingDelete}
                        className="m-0"
                        size="lg"
                    />
                )}
              </>
          )}
        </div>
      </div>
      <TemplateContent
        visible={showContent}
        closeModal={closeModalContent}
        text={text}
        id={id}
        name={name}
        editMode={editMode}
        setEditMode={setEditMode}
        refreshTemplates={refreshTemplates}
        isText={isText}
        isAudio={isAudio}
        isEmail={isEmail}
        filePath={filePath}
        title={title}
      />
    </>
  );
};

export default React.memo(PreStoredMessagesItem);
