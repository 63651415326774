import * as React from "react";

import { MenuHeader } from "../Atomics";
import CIcon from "@coreui/icons-react";
import { cilPeople } from "@coreui/icons";
import { CSpinner } from "@coreui/react";
import "../staff/StaffList.css";
import "./UsersList.scss";
import UsersRow from "./UsersRow";

// interfaces
import { User } from "../types";

const UsersList: React.FC<any> = ({ users, loading, refreshUsers }) => {
  const [isPreviousAction, setIsPreviousAction] = React.useState<boolean>(false);

  return (
    <>
      <MenuHeader className="d-flex pt-3 pb-3 justify-content-around table-size">
        <CIcon
            icon={cilPeople}
            size="xl"
            className="staff-icon section-width-users-1"
        />
        <p className="text-color section-width-users-2 d-block d-sm-none">Users</p>
        <p className="text-color section-width-users-2 d-none d-sm-block">User</p>
        <p className="text-color section-width-users-3 d-none d-sm-block">Email</p>
        <p className="text-color section-width-users-4 d-none d-sm-block">Phone</p>
        <p className="text-color section-width-users-4 d-none d-sm-block">Role</p>
        <span className="text-color section-width-users-5 d-none d-sm-block"></span>
      </MenuHeader>
      {!loading ? (
          users.map((user: User, index: string) => {
            return (
            <UsersRow
              refreshUsers={refreshUsers}
              user={user}
              key={index}
              setPreviousAction={setIsPreviousAction}
              previousAction={isPreviousAction}
            />
          );
        })
      ) : (
        <div className="spinner">
          <CSpinner variant="grow" />
        </div>
      )}
    </>
  );
};

export default React.memo(UsersList);