/* eslint-disable no-unused-vars */
enum ApiErrorStatusCode {
  BadRequest = 400,
  AuthenticationError = 401,
  AuthorizationError = 403,
  ConflictError = 409,
  ServerError = 500,
  // some others
  NonApiError = 800,
}

export default ApiErrorStatusCode;
