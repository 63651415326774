import * as React from "react";

import { MenuHeader } from "../Atomics";
import { CSpinner } from "@coreui/react";
import "./ParentsList.scss";
import { removeContactFromParents } from "../../services/parentsService";
import HandleUIError from "../../utils/HandleUIError";
import toast from "react-hot-toast";
import confirmationDialog from "../toast-dialogs/ConfirmationDialog";
import "./ParentsList.scss"
// interfaces
import { Parents } from "../types";
import ParentsRow from "./ParentsRow";
import {createActivityReq} from "../../services/activityLogService";

interface Props {
  parents: Array<Parents>,
  loading: boolean,
  refreshParents: () => void
}

const ParentsList: React.FC<Props> = ({ parents, loading, refreshParents }) => {
  async function removeUser(parentId: number): Promise<void> {
    try {
      await toast.promise(removeContactFromParents(parentId), {
        loading: "Removing parent contact...",
        success: "Parent removed from organization",
        error: "Error removing parent from organization",
      });
      await refreshParents();
      await createActivityReq('Contact Successfully Removed From Community.')
    } catch (e) {
      HandleUIError(e);
    }
  }

  function onPressRemoveUser(parentId: number) {
    const confirmationDialogOptions = {
      title:
        "Are you sure you want to remove this parent contact?",
      confirmAction: () => removeUser(parentId),
    };
    confirmationDialog(confirmationDialogOptions);
  }

  return (
    <>
      <MenuHeader className="d-flex pt-3 pb-3 justify-content-around">
        <p className="text-color section-width-parents-1 d-block d-sm-none">Users</p>
        <p className="text-color section-width-parents-1 d-none d-sm-block">Name</p>
        <p className="text-color section-width-parents-2 d-none d-sm-block">Mail</p>
        <p className="text-color section-width-parents-3 d-none d-sm-block">Phone</p>        
        <p className="text-color section-width-parents-4 d-none d-sm-block"></p>
      </MenuHeader>
      {!loading ? (
        parents.map((element: Parents, index: number) => {
          return (
              <ParentsRow
              parent={element}
              key={index}
              removeUser={onPressRemoveUser}
              refreshParents={refreshParents}
              />
          );
        })
      ) : (
        <div className="spinner">
          <CSpinner variant="grow" />
        </div>
      )}
    </>
  );
};

export default ParentsList;