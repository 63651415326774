import React, {useEffect, useState} from 'react';
import {IconButton, MenuHeader, PageContainer} from "../components/Atomics";
import {cilPencil, cilPlus, cilTrash} from "@coreui/icons";
import HandleUIError from "../utils/HandleUIError";
import {appUserStatus, getAppUsers} from "../services/appUserService";
import {CSpinner} from "@coreui/react";
import MapModal from "../components/MapModal";
import {getAlarmAlerts} from "../services/alarmAlertsService";
import CreateAlarmAlert from "../components/alarmAlerts/CreateAlarmAlert";
import usePermission from "../hooks/usePermission";

const AlarmAlert = () => {
    const { checkPermission } = usePermission()
    const [users, setUsers] = useState<any>([]);
    const [editModal, setEditModal] = useState(false)
    const [isEdit, setIsEdit] = useState<any>(null)
    const [loading, setLoading] = useState(false)
    const [loadingStatus, setLoadingStatus] = useState(false)




    const onPressEdit = (data: any) => {
        setIsEdit(null)
        setIsEdit(data)
        setEditModal(true)
    }


    const handleStatus = (data: any) => {
        setLoadingStatus(true)
        console.log('!data.status', !data.active)
        const body = {
            status: !data.active
        }
        appUserStatus(data?.id, body)
            .then((res: any) => {
                console.log('status1', res)
                setLoadingStatus(false)
                getAlarms()
            })
            .catch((e: any) => {
                HandleUIError(e);
                setLoadingStatus(false)
            });
    }

    const getAlarms = () => {
        getAlarmAlerts()
            .then((res: any) => {
                console.log('users1', res)
                setUsers(res);
            })
            .catch((e: any) => {
                HandleUIError(e);
            });
    }

    useEffect(() => {
        getAlarms()
    }, []);

    return (
        <>
            <div className="bg-main pb-5 over-flow-auto h-100">
                <PageContainer className="page-container-staff d-flex flex-column justify-content-start pb-3 mx-4">
                    <div className="row align-items-end justify-content-between mb-4">
                        <div className="col-auto">
                            <h3 className="text-white mb-0">
                                Alarm Alert
                            </h3>
                        </div>
                        <div className="col-2 ms-auto d-none">
                            <select
                                className="form-control shadow-none text-white"
                                style={{
                                    backgroundColor: '#38373d',
                                    border: '2px solid #44444c',
                                }}
                            >
                                <option value="DESC">Descending Order</option>
                                <option value="ASC">Ascending Order</option>
                            </select>
                        </div>
                        <div className="col-auto d-none">
                            <div className="wrap mg-bot mg-lt center-text mb-0">
                                <p className="text-light me-2">Search:</p>
                                <input
                                    // value={searchValue}
                                    // onChange={onChangeSearchValue}
                                    className="input-search"
                                    type="text"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <>
                            <MenuHeader className="d-flex pt-3 pb-3 px-2 justify-content-around">
                                <p className="text-color col d-none d-sm-block px-2">
                                    Type
                                </p>
                                <p className="text-color col d-none d-sm-block">
                                    Sound
                                </p>
                                <div className="section-width-parents-4 d-flex justify-content-end align-items-center">
                                    &nbsp;
                                </div>
                                {/*<p className="text-color col d-none d-sm-block">
                                    Action
                                </p>*/}
                                {/*<p className="text-color section-width-parents-4 d-none d-sm-block"></p>*/}
                            </MenuHeader>
                            {loading ? (
                                <div className="d-flex justify-content-center mt-4">
                                    <CSpinner variant="grow" />
                                </div>
                            ) : (
                                <>
                                    {users?.length > 0 ? (
                                        <>
                                            {users.map((item: any) => (
                                                <div key={item?.id} className="d-flex py-3 px-3 user-information-container justify-content-around">
                                                    <div className="col d-flex justify-content-start">
                                                        <p className="text-light word-break">
                                                            {item?.type}
                                                        </p>
                                                    </div>
                                                    <div className="col d-flex justify-content-start">
                                                        <p className="text-light word-break">
                                                            {item?.sound && (
                                                                <audio
                                                                    controls
                                                                    src={item?.sound}
                                                                    className="d-block ms-3"
                                                                />
                                                            )}
                                                        </p>
                                                    </div>
                                                    <div className="section-width-parents-4 d-flex justify-content-end align-items-center">
                                                        {checkPermission("Edit Alarm Alert") && (
                                                            <div className="margin-icon">
                                                                <IconButton
                                                                    icon={cilPencil}
                                                                    size="lg"
                                                                    onClick={() => onPressEdit(item)}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    ) : <h5 className="text-white text-center py-2">DATA NOT FOUND</h5>}
                                </>
                            )}
                        </>
                    </div>
                </PageContainer>
            </div>


            {Boolean(isEdit) && (
                <CreateAlarmAlert
                    visible={editModal}
                    closeModal={() => setEditModal(false)}
                    isEdit={isEdit}
                    refreshAlarm={getAlarms}
                />
            )}

        </>
    );
};

export default AlarmAlert;