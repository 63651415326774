import { AxiosError } from "axios";
import ApiErrorStatusCode from "./ApiErrorStatusCode";
/**
 * ApiError class for unifying all errors from API service layer
 */
export default class ApiError extends Error {
  statusCode: number = ApiErrorStatusCode.NonApiError as number;
  sourceError: AxiosError | Error;

  constructor(sourceError: AxiosError | Error) {
    super(sourceError.message);

    this.sourceError = sourceError;

    const axiosErr = sourceError as AxiosError;
    if (axiosErr.response) {
      this.statusCode = axiosErr.response.status;
    }
  }
}
