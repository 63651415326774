import * as React from "react";
import { AlertHistoryResponse } from "../../services/types";
import "../../views/Alerts.scss";
import moment from "moment";

//services
import { convertUTCtoLocale } from "../../utils/formatConverters";
import { useState } from "react";
import ViewAlerts from "./ViewAlerts";
import CIcon from "@coreui/icons-react";
import { cilLocationPin } from "@coreui/icons";

interface Props {
  alert: AlertHistoryResponse;
  setEditData: (data: any) => void;
}

const AlertInformation: React.FC<Props> = ({ alert, setEditData }) => {
  const [isView, setIsView] = useState<any>(null)
  const zoneName = alert.zone ? alert.zone.name : "From BackOffice";
  const localeDate = convertUTCtoLocale(alert.createdAt);
  return (
    <div className="alert-information d-flex py-3 p-information table-size">
      <div className="d-flex w-100 p-information">
        <p className="text-light ps-sm-4 alert-width-section-1" role="button" onClick={() => setIsView(alert)}>
          {alert.type ? alert.type : alert.alertType.name}
        </p>
        <p className="text-light alert-width-section-2">
          {alert.user.firstName + " " + alert.user.lastName}
        </p>
        <p className="text-light alert-width-section-3">
          {moment(localeDate).format("L")} At {moment(localeDate).format("LT")}
        </p>
        <p
          role="button"
          className="text-light alert-width-section-4"
          onClick={() => setEditData(alert)}
        >
          <CIcon icon={cilLocationPin} size="sm" className="add-icon" />
          {" "}
          {zoneName}

        </p>
      </div>
      <p className="text-color alert-width-section-5 d-block d-sm-none">
        Drill mode:
      </p>
      <p className="text-light alert-width-section-5 custom-p-responsive">
        {alert.isDrill ? "Yes" : "No"}
      </p>

      <p className="text-light alert-width-section-5 custom-p-responsive">
        <button
          className="btn btn-primary btn-sm"
          onClick={() => setIsView(alert)}
        >
          View
        </button>
      </p>

      {isView && (
        <ViewAlerts
          visible={Boolean(isView)}
          closeModal={() => setIsView(null)}
          isView={isView}
        />
      )}
    </div>
  );
};

export default React.memo(AlertInformation);
