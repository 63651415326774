import * as React from "react";

interface Props {
  className?: string;
}

const ChronometerAudioMessage: React.FC<Props> = ({ className }) => {
  const [time, setTime] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  function secondsToString(seconds: number) {
    let minute: number | string = Math.floor((seconds / 60) % 60);
    minute = minute < 10 ? "0" + minute.toString() : minute;
    let second: number | string = seconds % 60;
    second = second < 10 ? "0" + second.toString() : second;
    return minute.toString() + ":" + second.toString();
  }

  return <p className={className}>{secondsToString(time)}</p>;
};

export default ChronometerAudioMessage;
