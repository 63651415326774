import axiosConfig from "./axiosConfig";
import { AxiosError, AxiosResponse } from "axios";
import ApiError from "../utils/ApiError";
import {Roles} from "../components/types";

interface PaginationResponse {
    data: Array<Roles>;
    numberOfTotalParents: number;
}

export async function searchParents(
    limit: number,
    page: number,
    search: string
  ): Promise<PaginationResponse> {
    try {
      const response = await axiosConfig.get<any>(
        `/admin/get-group-messages`
      );
      const data = response.data.data as Array<Roles>;
      return { data, numberOfTotalParents: response.data.meta.total };
    } catch (e) {
      const err = e as AxiosError | Error;
      throw new ApiError(err);
    }
  }

export async function searchAmergencyAlert(
    limit: number,
    page: number,
    search: string
): Promise<PaginationResponse> {
    try {
        const response = await axiosConfig.get<any>(
            `/admin/get-emergency-alerts`
        );
        const data = response.data.data as Array<Roles>;
        return { data, numberOfTotalParents: response.data.meta.total };
    } catch (e) {
        const err = e as AxiosError | Error;
        throw new ApiError(err);
    }
}

export async function getParents(
  limit: number,
  page: number,
  search?: any,
  type?: any
): Promise<Array<Roles>> {
    try {
        const response = await axiosConfig.get<AxiosResponse>(`admin/get-group-messages?limit=${limit}&page=${page}&name=${search ? search : ''}&type=${type ? type : ''}`);
        return response.data.data as Array<Roles>;
    } catch (e) {
        const err = e as AxiosError | Error;
        throw new ApiError(err);
    }
}

export async function getEmergencyAlertParent(
    limit: number,
    page: number,
    search?: any,
    type?: any
): Promise<Array<Roles>> {
    try {
        const response = await axiosConfig.get<AxiosResponse>(`admin/get-emergency-alerts?limit=${limit}&page=${page}&name=${search ? search : ''}&type=${type ? type : ''}`);
        return response.data.data as Array<Roles>;
    } catch (e) {
        const err = e as AxiosError | Error;
        throw new ApiError(err);
    }
}

export async function searchRolesUser(
    limit: number,
    page: number,
    search: string,
): Promise<PaginationResponse> {
    try {
        const response = await axiosConfig.get<any>(
            `/admin/roles-user-list/1`
        );
        const data = response.data.data as Array<Roles>;
        return { data, numberOfTotalParents: response.data.meta.total };
    } catch (e) {
        const err = e as AxiosError | Error;
        throw new ApiError(err);
    }
}

export async function getRolesUser(
    limit: number,
    page: number
): Promise<Array<Roles>> {
    try {
        const response = await axiosConfig.get<AxiosResponse>(`/admin/roles-user-list/1`);
        return response.data.data as Array<Roles>;
    } catch (e) {
        const err = e as AxiosError | Error;
        throw new ApiError(err);
    }
}

export async function saveParents(name: string, email: string | null, phone: string | null): Promise<void> {
    try {
        const body = {
            role_name: name,
            email,
            phone
          };
          await axiosConfig.post("admin/roles", body);
    } catch (e) {
        const err = e as AxiosError | Error;
        throw new ApiError(err);
    }
}

export async function removeContactFromParents(
  parentId: number
): Promise<Array<any>> {
  try {
    const response = await axiosConfig.delete<AxiosResponse>(
      `/admin/roles/${parentId}`
    );
    return response.data.data as Array<any>;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function editContactFromParents(
  parentId: number,
  name: string,
  email: string | null,
  phone: string | null
): Promise<Array<any>> {
  try {
    const body = {
      role_name: name,
      email,
      phone
    };
    const response = await axiosConfig.put<AxiosResponse>(
      `/admin/roles/${parentId}`,
      body
    );
    return response.data.data as Array<any>;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}