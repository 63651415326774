import axiosConfig from "./axiosConfig";
import { AxiosError, AxiosResponse } from "axios";
import ApiError from "../utils/ApiError";
import { Roles } from "../components/types";

interface PaginationResponse {
  data: Array<Roles>;
  numberOfTotalParents: number;
}


export async function getActivityReq(StaffId: number): Promise<Array<Roles>> {
  try {
    const response = await axiosConfig.get<AxiosResponse>(
      `/admin/activity-logs${StaffId ? `/${StaffId}` : ''}`
    );
    return response.data.data as Array<any>;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function createActivityReq(message: any): Promise<Array<Roles>> {
  try {
    const response = await axiosConfig.post<AxiosResponse>(
        `/admin/activity-logs`,
        { description: message},
    );
    return response.data.data as Array<any>;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}
