import * as React from "react";
import "./Facility.scss";

import { useHistory } from "react-router-dom";
import CIcon from "@coreui/icons-react";
import { cilPlus } from "@coreui/icons";

//componentes
import { MenuHeader } from "../components/Atomics";
import { GoogleMap } from "../components/google-map";
import { PerimeterItem } from "../components/facility";
import toast from "react-hot-toast";

//services
import {
  getAllZones,
  getCoordinatesByDirectionString,
} from "../services/facilityService";
import { getOrganizationInfo } from "../services/loggedUserService";
import HandleUIError from "../utils/HandleUIError";
import { ZoneResponse } from "../services/types";
import {saveZoneToDisplay} from "../redux/reducers/zoneReducer";
import {useDispatch} from "react-redux";
import {useMemo, useState} from "react";
import usePermission from "../hooks/usePermission";

const Facility: React.FC = () => {
  const { checkPermission } = usePermission()
  const dispatch = useDispatch();
  const history = useHistory();
  const [zones, setZones] = React.useState<ZoneResponse[]>();
  const [initialCoordinate, setInitialCoordinates] =
    React.useState<any>();
  const [zonesToDisplay, setZonesToDisplay] = React.useState<ZoneResponse[]>(
    []
  );
  const [search, setSearch] = useState('')

  React.useEffect(function () {
    refreshZones().catch((e) => console.warn(e));
    // getInitialCoordinates().catch((e) => console.warn(e));
  }, []);

  async function refreshZones() {
    try {
      const zonesResponse = await getAllZones();
      const cordinates = zonesResponse?.find((item) => item?.isShow === true)
      setInitialCoordinates({
        lng: cordinates?.zonePoints[0]?.longitude,
        lat: cordinates?.zonePoints[0]?.latitude
      })
      setZonesToDisplay(zonesResponse);
      setZones(zonesResponse);
    } catch (e) {
      HandleUIError(e);
    }
  }

  function goNewPerimeter() {
    history.push("/new-perimeter");
  }

  async function getInitialCoordinates() {
    try {
      const { address } = await getOrganizationInfo();
      const coordinates = await getCoordinatesByDirectionString(address);
      console.log('coordinates===', coordinates)
      if (coordinates) {
        setInitialCoordinates({
          lat: coordinates.latitude,
          lng: coordinates.longitude,
        });
      } else {
        toast.error("Could not get organization address");
      }
    } catch (e) {
      HandleUIError(e);
    }
  }

  function hideZone(zoneId: number) {
    setZonesToDisplay(zonesToDisplay.filter((zone) => zone.id !== zoneId));
    dispatch(saveZoneToDisplay(zonesToDisplay.filter((zone) => zone.id !== zoneId)))
  }

  function showZone(zone: ZoneResponse) {
    setZonesToDisplay([...zonesToDisplay, zone]);
    dispatch(saveZoneToDisplay([...zonesToDisplay, zone]))
  }

  const filteredZones = useMemo(() => {
    return zones?.filter((item: any) => {
      return Object?.keys(item)?.some((key) => {
        return String(typeof item[key] == 'object' ? item[key]?.name : item[key])?.toLowerCase()?.includes(search?.toLowerCase())
      })
    })
  }, [zones, search])

  return (
    <div className="bg-main pb-3 pt-2 over-flow-auto h-100">
      <div className="perimeters-container rounded pb-4 m-2 mt-5 mt-sm-4 mx-sm-4 px-2 px-sm-4">
        <div className="d-flex">
          <p className="text-color fs-4 mb-4">Perimeters</p>
          <div className="col-lg-2 ms-auto me-3">
            <input
              type="search"
              className="input-custom w-100 small"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          {checkPermission('Create and Edit Facility') && (
              <div className="add-icon-container" onClick={goNewPerimeter}>
                <CIcon icon={cilPlus} size="xl" className="add-icon" />
              </div>
          )}
        </div>
        <MenuHeader className="d-flex">
          <p className="text-color name-text name-width mx-2">Name</p>
          <p className="text-color name-text name-width mx-0">Address</p>
          <p className="text-color name-text name-width mx-0">Users Count</p>
          <p className="text-color name-text col-1 invisible">
            &nbsp;
          </p>
        </MenuHeader>
        <div className="perimeters">
          {filteredZones?.map((zone, index) => (
            <PerimeterItem
              zone={zone}
              index={index}
              refreshZones={refreshZones}
              hideZone={hideZone}
              showZone={showZone}
              setInitialCoordinates={setInitialCoordinates}
            />
          ))}
          {zones?.length === 0 && (
            <p className="fs-5 text-light py-3 ms-4">
              No perimeters to display
            </p>
          )}
        </div>
      </div>
      {console.log('zonesToDisplay', zonesToDisplay)}
      {(zonesToDisplay) && (
          <div className="map-container rounded mx-2 mx-sm-4 p-1">
            <GoogleMap
                center={initialCoordinate}
                zoom={17}
                zones={zonesToDisplay}
                zonesOrder={zones?.map((zone, index) => ({
                  id: zone.id,
                  index: zone?.users?.length,
                }))}
                pointerMap={false}
            />
          </div>
      )}
    </div>
  );
};

export default Facility;
