import axiosConfig from "./axiosConfig";
import { AxiosError, AxiosResponse } from "axios";
import ApiError from "../utils/ApiError";
import { getOrganizationInfo } from "./loggedUserService";
import { store } from "../redux/store";
import { saveOrganization } from "../redux/reducers/organizationReducer";
import { OrganizationSettings } from "./types";

export async function handlerDrillMode(
  drillMode: boolean
  ): Promise<void> {
    try {
      await axiosConfig.patch<AxiosResponse>(
        `/admin/organization/drill-mode`,
        {
          "isDrillMode": drillMode,
        }
      );
      const res = await getOrganizationInfo() 
      store.dispatch(saveOrganization(res));
    } catch (e) {
      const err = e as AxiosError | Error;
      throw new ApiError(err);
    }
  }

export async function handleOrganizationConfig(
  emergencyNumberDrill: number,
  alertTypeId: number,
  deviceId: number
): Promise<void> {
  try{
    await axiosConfig.patch<AxiosResponse>(
      '/admin/organization/settings',
      {
        "emergencyNumberDrill": emergencyNumberDrill,
        "alertTypeId": alertTypeId,
        "deviceId": deviceId
      }
    );
  } catch (e){
    const err = e as AxiosError | Error;
    throw new ApiError(err)
  }
}

export async function getOrganizationConfig(): Promise<OrganizationSettings> {
  try{
    const response = await axiosConfig.get<AxiosResponse>("/admin/organization/settings");
    return response.data.data as OrganizationSettings;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}