import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OrganizationState } from '../types';

const initialState: OrganizationState = {
    id: 0,
    name: "",
    isDrillMode: false,
};

export const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    saveOrganization: (state, action: PayloadAction<OrganizationState>) => {
      return action.payload;
    },
    clearOrganization: () => {
      return initialState
    },
    default: (state) => {
      return state;
    },
  },
});

export const { saveOrganization, clearOrganization } =
  organizationSlice.actions;
export default organizationSlice.reducer;