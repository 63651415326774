import * as React from "react";
import "./NewStaff.scss";
// import { useAppSelector } from "../../redux/hooks";
import HandleUIError from "../../utils/HandleUIError";
import { User } from "../types";
import toast from "react-hot-toast";

//components
import { CSpinner } from "@coreui/react";
import {
  Modal,
  Input,
  UserAvatar,
  Button,
  Spinner,
  IconButton,
} from "../Atomics";
import { cilX } from "@coreui/icons";

//services
import { addUserToGroup, searchUserToAddGroup } from "../../services/groupService";
import { useAppSelector } from "../../redux/hooks";

interface Props {
  visible: boolean;
  closeModal: () => void;
  refreshGroups: () => void;
  groupName: string;
  groupId: number;
  refreshUsersInGroup: () => void;
}

const AddStaffGroup: React.FC<Props> = ({
  visible,
  closeModal,
  groupName,
  groupId,
  refreshGroups,
  refreshUsersInGroup
}) => {
  const [search, setSearch] = React.useState("");
  const [usersResults, setUsersResults] = React.useState<any>([]);
  const [loadingResults, setLoadingResults] = React.useState<boolean>(false);
  const [addUserLoading, setAddUserLoading] = React.useState<boolean>(false);
  const [devicesResults, setDevicesResults] = React.useState<boolean>(false);
    const organizationId = useAppSelector(
      (state) => state.UserSlice.user.organizationId
    );

  const handleInput = (e: any) => {
    setSearch(e.target.value);
  };

  async function searchUsers(e: any) {
    if (e.charCode === 13) {
      setLoadingResults(true);
      setDevicesResults(false);
      try {
        const usersToAdd = await searchUserToAddGroup(search, organizationId);
        setUsersResults(usersToAdd);
        if (usersToAdd.length < 1) {
          setDevicesResults(true);
        }
        setLoadingResults(false);
      } catch (e) {
        setLoadingResults(false);
        HandleUIError(e);
      }
    }
  }

async function addUser(userId: number) {
    setAddUserLoading(true);
    try {
      await toast.promise(addUserToGroup(groupId, userId), {
        loading: "Adding user...",
        success: "User added to group",
        error: "Error adding user to group",
      });
      setAddUserLoading(false);
      refreshGroups();
      refreshUsersInGroup();
    } catch (e) {
      setAddUserLoading(false);
      HandleUIError(e);
    }
    closeModal();
  }

  function onCloseModal() {
    setSearch("");
    setUsersResults([]);
    closeModal();
  }

  return (
    <Modal visible={visible} onPressOut={onCloseModal}>
      <div className="new-staff-container">
        <div className="new-staff-header">
          <p className="text-light py-2 ps-4 fs-5">
            Add a staff to the group "{groupName}"
          </p>
          <IconButton icon={cilX} className="me-3" onClick={closeModal} />
        </div>
        <div className="search-phone">
          <p className="text-light fs-5 ms-3 pt-3">
            Search by Phone Number or Email Address
          </p>
          <Input
            className="mt-2 mb-3 ms-3 search-phone-input"
            onChange={handleInput}
            onKeyPress={searchUsers}
            autoFocus
          />
        </div>
        {usersResults.length > 0 && !loadingResults ? (
          <div className="users-container">
            {usersResults.map((user: User) => {
              return (
                <div className="user-preview-container" key={user.id}>
                  <UserAvatar iconLetter="CI" size={4.5} />
                  <p className="text-color fs-5 mt-2">
                    {user.firstName} {user.lastName}
                  </p>
                  {user.phone === null ? (
                    <p className="text-color fs-5 mt-2">-</p>
                  ) : (
                    <p className="text-color fs-5 mt-2">{user.phone}</p>
                  )}
                  <p className="text-color fs-5 mt-2">{user.email}</p>
                  <Button className="mt-7 button-add" onClick={() => addUser(user.id)}>
                    {!addUserLoading ? "Add" : <Spinner />}
                  </Button>
                </div>
              );
            })}

            {loadingResults && (
              <div className="new-staff-spinner-container">
                <CSpinner variant="grow" className="new-staff-spinner" />
              </div>
            )}
          </div>
        ) : (
          <div className="users-container">
            {loadingResults && (
              <div className="new-staff-spinner-container">
                <CSpinner variant="grow" className="new-staff-spinner" />
              </div>
            )}
            {!loadingResults && devicesResults && (
              <div className="user-preview-container">
                <p className="text-light fs-5 ms-3 pt-3">No results to show</p>
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default AddStaffGroup;
