import * as React from "react";
import "./UserLocationStatus.scss";

//components
import { StaffNumbers } from ".";
import { GoogleMap } from "../google-map";
import { getOrganizationInfo } from "../../services/loggedUserService";
import {
  getAllZones,
  getCoordinatesByDirectionString,
} from "../../services/facilityService";
import toast from "react-hot-toast";
import HandleUIError from "../../utils/HandleUIError";
import { ZoneResponse } from "../../services/types";
import {useDispatch, useSelector} from "react-redux";

const UserLocationStatus: React.FC = () => {
  const [zones, setZones] = React.useState<ZoneResponse[]>();
  const [initialCoordinate, setInitialCoordinates] = React.useState<any>(null);
  const [zonesToDisplay, setZonesToDisplay] = React.useState<ZoneResponse[]>(
    []
  );

  React.useEffect(function () {
    refreshZones().catch((e) => console.warn(e));
    // getInitialCoordinates().catch((e) => console.warn(e));
  }, []);

  async function refreshZones() {
    try {
      const zonesResponse = await getAllZones();
      const cordinates = zonesResponse?.find((item) => item?.isShow === true)
      setInitialCoordinates({
        lat: cordinates?.zonePoints[0]?.latitude,
        lng: cordinates?.zonePoints[0]?.longitude
      })
      setZonesToDisplay(zonesResponse)
      setZones(zonesResponse);
    } catch (e) {
      HandleUIError(e);
    }
  }

  async function getInitialCoordinates() {
    try {
      const { address } = await getOrganizationInfo();
      const coordinates = await getCoordinatesByDirectionString(address);
      if (coordinates) {
        setInitialCoordinates({
          lat: coordinates.latitude,
          lng: coordinates.longitude,
        });
      } else {
        toast.error("Could not get organization address");
      }
    } catch (e) {
      HandleUIError(e);
    }
  }

  return (
    <div className="new-staff-container w-100">
      <div className="new-template-header d-flex justify-content-between align-items-center">
        <p className="text-light py-2 ps-4 fs-5">User Location Status</p>
      </div>
      <div className="mt-3 mx-3">
        <StaffNumbers />

        {zonesToDisplay && initialCoordinate && (
            <div className="map-container-user-location">
              <GoogleMap
                  center={initialCoordinate}
                  zones={zonesToDisplay}
                  zoom={17}
                  // hideNumbers
                  zonesOrder={zones?.map((zone, index) => ({
                    id: zone.id,
                    index: zone.users?.length,
                  }))}
                  pointerMap={false}
              />
            </div>
        )}
      </div>
    </div>
  );
};

export default UserLocationStatus;
