import * as React from "react";

import {
  PageContainer,
  Button,
  Input,
  MenuHeader,
  Divider,
  Spinner,
} from "../Atomics";
import { ButtonForGroup, GroupButtons } from "../Atomics/GroupButtons";

//components
import { Pagination } from "..";
import { CAccordion, CSpinner } from "@coreui/react";
import { StaffRecipients, GroupRecipients } from ".";

//services
import { Devices, User } from "../types";
import HandleUIError from "../../utils/HandleUIError";
import { getAllGroups } from "../../services/groupService";
import { GroupResponse } from "../../services/types";
import { searchUsers, getAllUsers } from "../../services/staffService";

interface Props {
  onSelectedGroup: (groupId: number[]) => void;
  onPressSend: (devicesIds: number[]) => Promise<void>;
}

type RecipientsOptions = "staff" | "group";

const Recipients: React.FC<Props> = ({ onPressSend, onSelectedGroup }) => {
  const [devicesPageSize] = React.useState<number>(7);
  const [numberOfPages, setNumberOfPages] = React.useState<number>(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [selectedRecipients, setSelectedRecipients] = React.useState<Devices[]>(
    []
  );
  const [selectedGroupRecipients, setSelectedGroupRecipients] = React.useState<
    number[]
  >([]);
  const [selecAllLoading, setSelectAllLoading] = React.useState(false);
  const [recipientsOptions, setRecipientsOptions] =
    React.useState<RecipientsOptions>("staff");
  const [groups, setGroups] = React.useState<GroupResponse[]>([]);
  const [searchText, setSearchText] = React.useState("");
  const [staffs, setStaffs] = React.useState<User[]>([]);

  function onChangeSearch(e: any) {
    setSearchText(e.target.value);
  }

  function selectStaff() {
    setRecipientsOptions("staff");
  }

  function selectGroup() {
    setRecipientsOptions("group");
  }

  React.useEffect(
    function () {
      onSelectedGroup(selectedGroupRecipients);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRecipients, selectedGroupRecipients]
  );

  React.useEffect(
    function () {
      refreshStaff();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage]
  );

  React.useEffect(function () {
    getGroups().catch((e) => console.warn(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(
    function () {
      setSelectedRecipients([]);
      setSelectedGroupRecipients([]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [recipientsOptions]
  );

  async function refreshStaff() {
    setLoading(true);
    try {
      const staffsResponse = await searchUsers(
          '',
        devicesPageSize,
        currentPage,
        searchText
      );
      setStaffs(staffsResponse.data);
      setNumberOfPages(staffsResponse.numberOfTotalUsers / devicesPageSize);
      setLoading(false);
    } catch (e) {
      HandleUIError(e);
      setLoading(false);
    }
  }

  React.useEffect(
    function () {
      //this function waits for the user stop typing before throw de request (have 1.5 seconds to margin)
      //this prevent the user from sending a request for every keystroke
      async function searchStaffsDevices() {
        setLoading(true);
        try {
          const staffsData = await searchUsers(
              '',
            devicesPageSize,
            currentPage,
            searchText
          );
          setStaffs(staffsData.data);
          setCurrentPage(1);
          setNumberOfPages(staffsData.numberOfTotalUsers / devicesPageSize);
          setLoading(false);
        } catch (e) {
          HandleUIError(e);
          setLoading(false);
        }
      }
      let timer = setTimeout(() => {
        searchStaffsDevices();
      }, 1000);
      return () => clearTimeout(timer);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchText]
  );

  function onCheckGroupRecipient(groupId: number, isChecked: boolean) {
    if (!isChecked) {
      setSelectedGroupRecipients([...selectedGroupRecipients, groupId]);
    } else {
      const filteredGroups = selectedGroupRecipients.filter(
        (id) => id.toString() !== groupId.toString()
      );
      setSelectedGroupRecipients(filteredGroups);
    }
  }

  async function onPressSelectAll() {
    setSelectAllLoading(true);
    try {
      const staffsResponse = await getAllUsers();
      let devices: Devices[] = [];
      staffsResponse.forEach((staffs) => {
        devices = [...devices, ...staffs.devices];
      });
      setSelectedRecipients(devices);
      setSelectAllLoading(false);
    } catch (e) {
      setSelectAllLoading(false);
      HandleUIError(e);
    }
  }

  function onPressUnselectAll() {
    setSelectedRecipients([]);
  }

  function sendMessage() {
    const devicesIds = selectedRecipients.map((d) => d.id);
    console.log('devicesIds', devicesIds)
    onPressSend(devicesIds);
  }

  async function getGroups() {
    try {
      const groupsResponse = await getAllGroups();
      setGroups(groupsResponse);
    } catch (e) {
      HandleUIError(e);
    }
  }

  return (
      <>
        <div className="col-lg-6">
          <PageContainer className="d-flex flex-column justify-content-between m-0">
            <div>
              <div className="d-flex px-4 justify-content-between align-items-center messages-header-container">
                <p className="text-light fs-5">Recipients</p>
                <Button className="buttons-messages-screen" onClick={sendMessage}>
                  Send
                </Button>
              </div>
              <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-sm-between  px-4 pt-4 align-items-center">
                <GroupButtons>
                  <ButtonForGroup
                      label="Staff"
                      checked={recipientsOptions === "staff"}
                      onClick={selectStaff}
                  />
                  <ButtonForGroup
                      label="Group"
                      checked={recipientsOptions === "group"}
                      onClick={selectGroup}
                  />
                </GroupButtons>
                {recipientsOptions === "staff" && (
                    <div className="d-flex mt-3 mt-sm-0">
                      <p className="text-light fs-5 me-2 ms-4">Search:</p>
                      <Input
                          className="w-100"
                          onChange={onChangeSearch}
                          value={searchText}
                      />
                    </div>
                )}
              </div>
              <MenuHeader className="d-flex justify-content-between mx-4 mt-4 py-3">
                <p className="text-color ms-2">
                  {recipientsOptions === "staff" ? "Staffs" : "Groups"}
                </p>
                {recipientsOptions === "staff" && (
                    <div className="d-flex align-items-center pe-2">
                      <p
                          className="text-color me-1 cursor-pointer"
                          onClick={onPressSelectAll}
                      >
                        Select All
                      </p>
                      {selecAllLoading && (
                          <Spinner className="text-light select-all-spinner" />
                      )}
                      <p
                          className="text-color me-1 cursor-pointer"
                          onClick={onPressUnselectAll}
                      >
                        - Unselect All
                      </p>
                    </div>
                )}
              </MenuHeader>
              <div className="d-flex flex-column justify-content-between recipients-staffs-container mb-3" style={{overflowY: 'auto', maxHeight: 456}}>
                {recipientsOptions === "staff" ? (
                    <CAccordion flush className="px-4">
                      {!loading ? (
                          staffs?.map((staff, index) => {
                            return (
                                <StaffRecipients
                                    key={staff.id}
                                    staff={staff}
                                    isLast={index === staffs.length - 1}
                                    setSelectedRecipients={setSelectedRecipients}
                                    selectedRecipients={selectedRecipients}
                                />
                            );
                          })
                      ) : (
                          <div className="d-flex justify-content-center mt-4">
                            <CSpinner variant="grow" />
                          </div>
                      )}
                    </CAccordion>
                ) : (
                    <>
                      {groups?.map((group, index) => {
                        return (
                            <div key={group.id}>
                              <GroupRecipients
                                  id={group.id}
                                  name={group.name}
                                  onChekedRecipient={onCheckGroupRecipient}
                              />
                              {index !== groups.length - 1 && (
                                  <div className="px-4 mt-3">
                                    <Divider />
                                  </div>
                              )}
                            </div>
                        );
                      })}
                    </>
                )}
              </div>
            </div>
            {numberOfPages > 1 && recipientsOptions === "staff" && (
                <div className="mb-3 mt-3 ms-4">
                  <Pagination
                      numberOfPages={numberOfPages}
                      setCurrentPage={setCurrentPage}
                      currentPage={currentPage}
                  />
                </div>
            )}
          </PageContainer>
        </div>
      </>
  );
};

export default Recipients;
