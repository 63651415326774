import * as React from "react";

import { useAppSelector } from "../../redux/hooks";
import { Divider } from "../Atomics";
import { signOut } from "../../services/SecurityService";
import {useHistory, useLocation} from "react-router-dom";
interface Props {
  src?: string;
  size?: number;
  iconLetter?: string;
  color?: string;
  isLoggedUser?: boolean;
}

const UserAvatar: React.FC<Props> = ({
  src,
  size,
  iconLetter,
  color,
  isLoggedUser,
}) => {
  const history = useHistory();
  const [visibleMenu, setVisibleMenu] = React.useState(false);
  function toggleMenu() {
    if (isLoggedUser) setVisibleMenu(!visibleMenu);
  }
  const style: React.CSSProperties = {
    height: `${size}rem`,
    width: `${size}rem`,
  };
  const styleNoImage: React.CSSProperties = {
    backgroundColor: color ? color : "#5B99E4",
    height: `${size}rem`,
    width: `${size}rem`,
  };
  const styleIconLetter: React.CSSProperties = {
    fontSize: size ? `${size * 0.5}rem` : "1rem",
  };
  const user = useAppSelector((state) => state.UserSlice.user);

  return (
    <div className="user-utils my-0">
      {src ? (
        <div className="cursor-pointer" onClick={toggleMenu}>
          <img src={src} alt="" className="user-avatar " style={style} />
        </div>
      ) : (
        <div
          style={styleNoImage}
          className="user-avatar-no-src cursor-pointer "
          onClick={toggleMenu}
        >
          <p
            style={styleIconLetter}
            className="text-light noselect-text text-uppercase"
          >
            {iconLetter}
          </p>
        </div>
      )}
      {visibleMenu && (
        <div>
          <div className="drop-down-background" onClick={toggleMenu} />
          <div className="drop-down-menu">
            <p className="dropdown-header text-light">{user.email}</p>
            <Divider />
            <p
              className="text-light my-2 ps-4 cursor-pointer drop-down-menu-buttons"
              onClick={async () => {
                await signOut()
                history.push('/')
                window.location.reload()
              }}
            >
              Sign Out
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserAvatar;
