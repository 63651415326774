import * as React from "react";
import AudioPlayer from "react-h5-audio-player";

interface Props {
  src?: string | undefined;
  className?: string;
}

const AudioPlayerCustom: React.FC<Props> = ({ src, className }) => {
  return (
    <div className={className}>
      <AudioPlayer src={src} showJumpControls={false} />
    </div>
  );
};

export default AudioPlayerCustom;
