import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {UserState, UserTokensState, UserDataState, RolesUserState} from "../../redux/types";

const initialState: UserState = {
  user: {
    id: 0,
    email: "",
    firstName: "",
    lastName: "",
    subscriptionCode: "",
    country: "",
    locale: "",
    phone: "",
    organizationId: null,
    isAdmin: false,
  },
  tokens: {
    access: "",
    refresh: "",
  },
  rolesUser: {
    id: 0,
  },
  permissions: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    saveUser: (state, action: PayloadAction<UserState>) => {
      return action.payload;
    },
    saveUserInfo: (state, action: PayloadAction<UserDataState>) => {
      return { ...state, user: action.payload };
    },
    saveUserPermission: (state, action: PayloadAction<any>) => {
      return { ...state, permissions: action.payload };
    },
    updateTokens: (state, action: PayloadAction<UserTokensState>) => {
      return { ...state, tokens: action.payload };
    },
    removeUser: () => {
      return initialState;
    },
    default: (state) => {
      return state;
    },
    saveRolesUser: (state, action: PayloadAction<RolesUserState>) => {
      return {...state, rolesUser: action.payload};
    },
  },
});

export const { saveUser, removeUser, updateTokens, saveUserInfo , saveUserPermission, saveRolesUser} =
  userSlice.actions;
export default userSlice.reducer;
