import * as React from "react";
import "../staff/NewStaff.scss";
import "./NewRole.scss";
import HandleUIError from "../../utils/HandleUIError";
import toast from "react-hot-toast";

//components
import { Modal, Input, Button, Spinner, IconButton } from "../Atomics";
import { cilX } from "@coreui/icons";
import { editContactFromParents } from "../../services/rolesService";
import {AudioPlayerCustom as AudioPlayer} from "../messages";

interface DataParents {
  parentId: number;
  type: String;
  fisrt_name: String;
  last_name: String;
  group_name: String;
  message: string;
}

interface Props {
  visible: boolean;
  closeModal: () => void;
  refreshParents: () => void;
  parentData: DataParents;
}

const EditRole: React.FC<Props> = ({
  visible,
  closeModal,
  refreshParents,
  parentData,
}) => {
  const [addParentLoading, setAddParentLoading] =
    React.useState<boolean>(false);

  async function changeParent(
    parentId: number,
    name: string,
    email: string,
    phone: string
  ): Promise<void> {
    setAddParentLoading(true);
    try {
      if (name === "" || name === null) {
        toast.error("Name cannot be empty");
        setAddParentLoading(false);
      } else {
        await editContactFromParents(parentId, name, email, phone);
        await refreshParents();
        toast.success("Role modified");
        setAddParentLoading(false);
        onCloseModal();
      }
    } catch (e) {
      setAddParentLoading(false);
      HandleUIError(e);
    }
  }

  function onCloseModal() {
    closeModal();
  }

  // @ts-ignore
  return (
    <Modal visible={visible} onPressOut={onCloseModal}>
      <div className="new-staff-container h-auto">
        <div className="new-staff-header">
          <p className="text-light py-2 ps-4 fs-5">View Group Alert</p>
          <IconButton icon={cilX} className="me-3" onClick={closeModal} />
        </div>

        <div className="d-flex p-2">
          <p className="text-light fs-5 ms-3 pt-3">Type: </p>
          <p className="text-color fs-5 ms-3 pt-3">{parentData?.type}</p>
        </div>

        <div className="d-flex p-2">
          <p className="text-light fs-5 ms-3 pt-3">Student Name: </p>
          <p className="text-color fs-5 ms-3 pt-3">
            {parentData?.fisrt_name} {parentData?.last_name}
          </p>
        </div>

        <div className="d-flex p-2">
          <p className="text-light fs-5 ms-3 pt-3">Group: </p>
          <p className="text-color fs-5 ms-3 pt-3">{parentData?.group_name}</p>
        </div>

        <div className="p-2 py-3">
          <p className="text-light fs-5 ms-3 pt-3">Message: </p>
          <div className="ms-3">
            {parentData?.type === "Image" && (
                <>
                  <img src={`${parentData?.message}`} alt="imgs" width={150} height={150}/>
                </>
            )}
            {(parentData?.type === "voice" || parentData?.type === "Voice") && (
                <>
                  <audio controls>
                    <source src={parentData?.message} type="audio/ogg" />
                    Your browser does not support the audio element.
                  </audio>
                </>
            )}

            {(parentData?.type === "message" ||
                parentData?.type === "mesage" ||
                parentData?.type === "Message") && (
                <>
                  <p className="text-color fs-5 ms-3 pt-3">{parentData?.message}</p>
                </>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditRole;
