import React, { useState } from 'react'
import gcLogoSq from "../../assets/gc_logo_square_white.svg";
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import { Button, Spinner } from '../../components/Atomics';
import { otpVerification } from '../../services/AuthSettingService';
import { useDispatch, useSelector } from 'react-redux';
import { saveOtp } from '../../redux/reducers/resetPasswordReducer';
import toast from 'react-hot-toast';
import HandleUIError from '../../utils/HandleUIError';


const OtpScreen = () => {
    const [otp, setOtp] = useState('')
    const [loading, setLoading] = useState(false)

    const history = useHistory()
    const dispatch = useDispatch()

    const email = useSelector((state) => state.resetPass?.email)


    const handleSubmit = async (e, otp) => {
        e.preventDefault();
        if (otp == "") {
            toast.error("Please Enter OTP", {id: 26});
            return;
        }
        if (otp?.length < 4 ) {
            toast.error("Otp must be of 4 digits", { id: 25});
            return;
        }

        try {
            const res = await otpVerification({ email, otp });
            if (res?.data?.status === "success") {
                dispatch(saveOtp(otp))
                toast.success(res?.data?.message, {id: 27});
                history.push('/new-password')
            } else if (res?.data?.status === "error") {
                toast.error(res?.data?.message, {id: 28});
            }
        } catch (e) {
            setLoading(false);
            HandleUIError(e);
        }

    };

    return (
        <div className="login-container otp-screen">
            <div className="login-border p-4">
                <div className="">
                    <h1 className="text-center mb-4 text-color login-title">
                        <img src={gcLogoSq} alt="GuardianCall" className="img-fluid" />
                    </h1>
                    <div className="mb-3">
                        <p className="text-light mb-2">Enter OTP </p>
                        <input
                            type="number"
                            maxLength={4}
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            // onChange={changeEmail}
                            max="4"
                            className="w-100 login-input"
                            placeholder='Enter OTP here'
                        />



                    </div>

                    <Button
                        onClick={(e) => handleSubmit(e, otp)}
                        className="w-100 fs-4 sign-in-button"
                        disabled={loading}
                    >
                        {loading ? <Spinner className="ms-3" /> : "Submit"}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default OtpScreen