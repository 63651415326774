import * as React from "react";

//components
import { PageContainer, Button, MenuHeader, Divider } from "../Atomics";
import { PreStoredMessagesItem, NewTemplate } from ".";
import { CSpinner } from "@coreui/react";
import { GroupButtons, ButtonForGroup } from "../Atomics/GroupButtons";
import { Pagination } from "..";

//services
import {
  getAllCustomTemplatesForPagination,
  getAllStandardTemplatesForPagination,
} from "../../services/messagesService";
import { TemplateMessageResponse } from "../../services/types";
import HandleUIError from "../../utils/HandleUIError";
import usePermission from "../../hooks/usePermission";

interface SwitchTemplatesProps {
  custom: boolean;
  standard: boolean;
}

const PreStoredMessages: React.FC = () => {
  const { checkPermission } = usePermission()
  const [visibleNewTemplate, setVisibleNewTemplate] = React.useState(false);
  const [templates, setTemplates] = React.useState<TemplateMessageResponse[]>();
  const [isLoading, setIsLoading] = React.useState(true);
  const [numberOfPages, setNumberOfPages] = React.useState<number>(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [templatesPageSize] = React.useState<number>(7);
  const [switchTemplate, setSwitchTemplate] =
    React.useState<SwitchTemplatesProps>({
      custom: true,
      standard: false,
    });

  function changeToCustom() {
    setSwitchTemplate({
      custom: true,
      standard: false,
    });
  }

  function changeToStandard() {
    setSwitchTemplate({
      custom: false,
      standard: true,
    });
  }

  React.useEffect(
    function () {
      getAllTemplates();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [switchTemplate.custom, currentPage]
  );

  React.useEffect(
    function () {
      setCurrentPage(1);
    },
    [switchTemplate.custom]
  );

  async function getAllTemplates() {
    setIsLoading(true);
    try {
      let templatesResponse: {
        data: TemplateMessageResponse[];
        numberOfTotalPages: number;
      };
      if (switchTemplate.custom) {
        templatesResponse = await getAllCustomTemplatesForPagination(
          templatesPageSize,
          currentPage
        );
      } else {
        templatesResponse = await getAllStandardTemplatesForPagination(
          templatesPageSize,
          currentPage
        );
      }
      setTemplates(templatesResponse.data);
      setNumberOfPages(
        templatesResponse.numberOfTotalPages / templatesPageSize
      );
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      HandleUIError(e);
    }
  }

  function closeVisibleNewTemplate() {
    setVisibleNewTemplate(false);
  }
  function openVisibleNewTemplate() {
    setVisibleNewTemplate(true);
  }

  return (
    <>
      <div className="col-lg-12 ">
        <PageContainer className="d-flex flex-column justify-content-between m-0 pb-3 p-0">
          <div>
            <div className=" d-flex px-4 justify-content-between align-items-center messages-header-container">
              <p className="text-light fs-5">Pre-Stored Messages</p>
              {checkPermission("Create and Edit Message") && (
                  <>
                    {switchTemplate.custom && (
                        <Button
                            className="buttons-messages-screen"
                            onClick={openVisibleNewTemplate}
                        >
                          New
                        </Button>
                    )}
                  </>
              )}

            </div>
            <GroupButtons className="mt-4 ms-4">
              <ButtonForGroup
                  label="Custom"
                  onClick={changeToCustom}
                  checked={switchTemplate.custom}
              />
              <ButtonForGroup
                  label="Standard"
                  checked={switchTemplate.standard}
                  onClick={changeToStandard}
              />
            </GroupButtons>
            <MenuHeader className="d-flex py-3 mx-4 mt-4">
              <p className="text-color ps-2 w-50">Messages</p>
              <p className="text-color w-50 me-5">Type</p>
            </MenuHeader>
            {!isLoading ? (
                templates?.map((template, index) => {
                  return (
                      <>
                        <PreStoredMessagesItem
                            key={index}
                            name={template.name}
                            id={template.id}
                            refreshTemplates={getAllTemplates}
                            isText={template.type === "text"}
                            isEmail={template.type === "html"}
                            isAudio={template.type === "audio"}
                            text={template.text}
                            title={template.title}
                            category={template.category}
                            filePath={template.filePath}
                        />
                        {index !== templates.length - 1 && (
                            <div className="px-4">
                              <Divider />
                            </div>
                        )}
                      </>
                  );
                })
            ) : (
                <div className="d-flex mt-5 justify-content-center">
                  <CSpinner variant="grow" />
                </div>
            )}
          </div>
          {numberOfPages > 1 && (
              <div className="ms-4 my-3">
                <Pagination
                    numberOfPages={numberOfPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
              </div>
          )}
        </PageContainer>
      </div>
      <NewTemplate
        refreshTemplates={getAllTemplates}
        visible={visibleNewTemplate}
        closeModal={closeVisibleNewTemplate}
      />
    </>
  );
};

export default PreStoredMessages;
