import AWS from 'aws-sdk';

AWS.config.update({
    region: 'us-east-1',
    credentials: new AWS.Credentials({
        accessKeyId: 'AKIA5FCD6TPPHMAIKXLM',
        secretAccessKey: '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',
    }),
});

const s3 = new AWS.S3({
    params: { Bucket: 'callguardian' },
    region: 'us-east-1',
});

export default s3;



// import AWS from 'aws-sdk';

// AWS.config.update({
//     region: 'us-east-2',
//     credentials: new AWS.Credentials({
//         accessKeyId: 'AKIARMS6ZO2PPZEPPOEH',
//         secretAccessKey: '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',
//     }),
// });

// const s3 = new AWS.S3({
//     params: { Bucket: 'callguardian' },
//     region: 'us-east-2',
// });

// export default s3;