import CIcon from "@coreui/icons-react";
import {
    cilBattery3,
    cilBattery5,
    cilBatteryAlert
  } from "@coreui/icons";

  
interface Props {
    batteryLevel: number | null;
    className: string;
  }
  

export const LowBatteryIcon: React.FC<Props> = ({batteryLevel, className}) =>{
    return(
        <>
        {batteryLevel !== null && (
            batteryLevel <= 20 ? <CIcon icon={cilBatteryAlert} size="xxl" className={className} />
            : batteryLevel > 20 && batteryLevel <= 50 ? <CIcon icon={cilBattery3} size="xxl" className={className} />
            : <CIcon icon={cilBattery5} size="xxl" className={className} />
        )} 
        </>
    )
}