import * as React from "react";
import "../../views/Facility.scss";

//components
import { Divider, IconButton } from "../Atomics";
import { cilLowVision, cilPen, cilTrash } from "@coreui/icons";
import { ConfirmationDialog } from "../toast-dialogs";
import showIcon from "../../assets/showIcon.png";
import hideIcon from "../../assets/hideIcon.png";

///services
import { ZoneResponse } from "../../services/types";
import {deleteZone, showHideZone} from "../../services/facilityService";
import HandleUIError from "../../utils/HandleUIError";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import {useEffect} from "react";
import usePermission from "../../hooks/usePermission";
import {createActivityReq} from "../../services/activityLogService";

interface Props {
  zone: ZoneResponse;
  index: number;
  refreshZones: () => Promise<void>;
  hideZone: (zoneId: number) => void;
  showZone: (zoneToShow: ZoneResponse) => void;
  setInitialCoordinates: (coordinates: { lat: number; lng: number }) => void;
}

const PerimeterItem: React.FC<Props> = ({
  index,
  zone,
  refreshZones,
  hideZone,
  showZone,
                                          setInitialCoordinates,
}) => {
  const { checkPermission } = usePermission()
  const [isLoading, setIsLoading] = React.useState(false);
  const [displayZone, setDisplayZone] = React.useState(zone?.isShow || false);

  const history = useHistory();

  function onPressEdit() {
    history.push(`/new-perimeter`, { zone });
  }

  function onPressDelete() {
    ConfirmationDialog({
      title: "Are you sure you want to delete this perimeter?",
      confirmAction: throwDeleteZone,
    });
  }

  async function throwDeleteZone() {
    setIsLoading(true);
    try {
      await deleteZone(zone.id);
      setIsLoading(false);
      refreshZones();
      toast.success("Perimeter deleted successfully");
      await createActivityReq('Facility Removed Successfully')
    } catch (e) {
      setIsLoading(false);
      HandleUIError(e);
    }
  }

  async function onPressShowOrHide(zoneDislay: any, zonePoints: any) {
    if (zoneDislay) {
      showZone(zone);
    } else {
      hideZone(zone.id);
    }
    setInitialCoordinates({
      lat: zonePoints[0]?.latitude,
      lng: zonePoints[0]?.longitude
    })
    setDisplayZone(zoneDislay);
    let response: any = await showHideZone(zone.id, zoneDislay)
    console.log('res==', response)
    if (response?.data?.data?.status == 'success') {
        refreshZones()
        toast.success('Zone updated successfully')
    }
  }

  console.log('zone===', zone)

  // useEffect(() => {
  //   hideZone(zone.id);
  // }, [])

  return (
    <div>
      <div className="d-flex pb-3 w-100">
        <div className="address-width d-flex w-100">
          <div className="name-width px-2">
            <p className="text-light mt-3">
              {index+1} - {zone.name}
            </p>
          </div>
          <div className="name-width px-2">
            <p className="text-light mt-3" dangerouslySetInnerHTML={{__html: zone?.address}} />
          </div>
          <div className="name-width px-3">
            <p className="text-light mt-3">
              {zone?.users?.length}
            </p>
          </div>
          <div className="d-flex flex-sm-row mt-3 pt-1 pe-0 pe-sm-4 ms-auto">
            {checkPermission('Hide and Un-Hide Facility') && (
                <img
                    src={displayZone ? showIcon : hideIcon}
                    className="perimeters-icon vision-icon"
                    alt="sow or hide"
                    onClick={() => onPressShowOrHide(!displayZone, zone?.zonePoints)}
                />
            )}
            {checkPermission('Create and Edit Facility') && (
                <IconButton
                    icon={cilPen}
                    className="perimeters-icon"
                    size="lg"
                    onClick={onPressEdit}
                />
            )}
            {checkPermission('Delete Facility') && (
                <IconButton
                    icon={cilTrash}
                    className="perimeters-icon"
                    size="lg"
                    isLoading={isLoading}
                    onClick={onPressDelete}
                />
            )}

          </div>
        </div>
      </div>
      <Divider />
    </div>
  );
};

export default React.memo(PerimeterItem);
