import * as React from "react";
import { Modal, IconButton } from "../components/Atomics";
import { cibWindows, cilX } from "@coreui/icons";
import { CButton, CSpinner } from "@coreui/react";
import "./Alert.scss";
import toast from "react-hot-toast";
import confirmationDialog from "../components/toast-dialogs/ConfirmationDialog";

//services
import { getAlerts, throwAlertById } from "../services/alertServices";
import { AlertTypeResponse } from "../services/types";
import HandleUIError from "../utils/HandleUIError";
import { useAppSelector } from "../redux/hooks";
import { useHistory } from "react-router-dom";
import { getButtonStyle } from "../helpers/uiTransforms";
import SendQuickAlerts from "../components/dashboard/SendQuickAlerts";
import {getAllGroups} from "../services/groupService";
import {useEffect} from "react";
import usePermission from "../hooks/usePermission";
import {createActivityReq} from "../services/activityLogService";
interface Props {
  visible: boolean;
  closeModal: () => void;
}

const Alert: React.FC<Props> = ({ visible, closeModal }) => {
  const { checkPermission } = usePermission()
  const [alertTypes, setAlertTypes] = React.useState<AlertTypeResponse[]>();
  const history = useHistory();
  const userId = useAppSelector((state) => state.UserSlice.user.id);
  const [groups, setGroups] = React.useState<any>([]);
  const [isEdit, setIsEdit] = React.useState<any>(null);

  React.useEffect(function () {
    getAlerts()
      .then((response) => {
        setAlertTypes(response.data);
      })
      .catch((e) => {
        HandleUIError(e);
      });
  }, []);

  function onPressSendAlert(alertType: string, alertId: number) {
    const confirmationDialogOptions = {
      title: `Are you sure you want to send this alert? (${alertType})`,
      confirmAction: () => {
        onPressThrowAlert(alertId).catch((e) => console.warn(e));
      },
    };
    confirmationDialog(confirmationDialogOptions);
  }

  async function onPressThrowAlert(alertTypeId: number) {
    try {
      await throwAlertById(alertTypeId, userId);
      history.push("/");
      toast.success("Alert thrown successfully");
      closeModal();
      await createActivityReq('Quick Alert Send Successfully')
    } catch (e) {
      HandleUIError(e);
    }
  }

  async function getTemplates() {
    try {
      setGroups([]);
      const groupsResponse: any = await getAllGroups();
      setGroups(groupsResponse);
    } catch (error) {
      HandleUIError(error);
    }
  }

  useEffect(() => {
    getTemplates().catch((e) => console.warn(e));
  }, []);

  return (
      <>
        <Modal visible={visible} onPressOut={closeModal}>
          <div className="new-staff-container">
            <div className="alert-header d-flex justify-content-between align-items-center">
              <p className="text-light py-2 ps-4 fs-5">Alerts</p>
              <IconButton icon={cilX} onClick={closeModal} />
            </div>
            <div
                className="alert-container"
                style={{ height: window.innerHeight * 0.8 }}
            >
              <div className="buttons-alerts-container-modal mt-2">
                {alertTypes ? (
                    alertTypes.map((alertType) => {
                      return (
                          <CButton
                              key={alertType.name}
                              shape="rounded-pill"
                              size="lg"
                              className="button-alert bg-button-alert-red w-100 py-1 my-1"
                              style={getButtonStyle(alertType.color)}
                              onClick={() => {
                                // setIsEdit(alertType);
                                checkPermission('Send Quick Alert') && onPressSendAlert(alertType.name, alertType.id)
                              }}
                              // onPressSendAlert(alertType.name, alertType.id)
                          >
                            <p className="alert-text px-3">{alertType.name} </p>
                          </CButton>
                      );
                    })
                ) : (
                    <CSpinner variant="grow" className="text-dark mt-5" />
                )}
              </div>
            </div>
          </div>
        </Modal>


        {isEdit && (
            <SendQuickAlerts
                visible={Boolean(isEdit)}
                closeModal={() => setIsEdit(null)}
                isEdit={isEdit}
                groups={groups}
            />
        )}
      </>
  );
};

export default Alert;
