import axiosConfig from "./axiosConfig";
import { AxiosError, AxiosResponse } from "axios";
import ApiError from "../utils/ApiError";
import { Devices } from "../components/types";

export async function getAllDevices(): Promise<Array<Devices>> {
  try {
    const response = await axiosConfig.get<AxiosResponse>("/admin/devices");
    return response.data.data as Array<Devices>;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

interface PaginationResponse {
  data: Array<Devices>;
  numberOfTotalDevices: number;
}

export async function getAllDevicesForPagination(
  limit: number,
  page: number
): Promise<PaginationResponse> {
  try {
    const response = await axiosConfig.get<any>(
      `/admin/devices?limit=${limit}&page=${page}`
    );
    const data = response.data.data as Array<Devices>;
    return { data, numberOfTotalDevices: response.data.meta.total };
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function getLowBatteryDevices(): Promise<Array<Devices>> {
  try {
    const response = await axiosConfig.get<AxiosResponse>(
      `/admin/devices?filter[battery][lt]=31`
    );
    return response.data.data as Array<Devices>;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function getFullDeviceInfo(deviceId: number): Promise<Devices> {
  try {
    //TODO: change endpont for admin/devices/{deviceId}
    const response = await axiosConfig.get<AxiosResponse>(
      `/devices/${deviceId}`
    );
    const deviceData = response.data.data as Devices;
    return deviceData;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}
