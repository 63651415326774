import * as React from "react";

//components
import CIcon from "@coreui/icons-react";
import { cilArrowLeft } from "@coreui/icons";

interface Props {
  onClick?: () => void;
  className?: string;
}

const GoBackButton: React.FC<Props> = ({ onClick, className }) => {
  return (
    <div
      className={"d-flex cursor-pointer align-items-center " + className}
      onClick={onClick}
    >
      <CIcon icon={cilArrowLeft} className="text-light me-1" size="lg" />
      <p className="text-light noselect-text">Go Back</p>
    </div>
  );
};

export default GoBackButton;
