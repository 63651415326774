
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// import {sendFcmToken} from "../services/alertServices";

// Scripts for firebase and firebase messaging
// importScripts("https://www.gstatic.com/firebasejs/8.2.0/firebase-app.js");
// importScripts("https://www.gstatic.com/firebasejs/8.2.0/firebase-messaging.js");

const firebaseConfig = {
    apiKey: "AIzaSyBEJYgORPe7HZ4tFCQad46q9rh44rXJ_5w",
    authDomain: "guardiancall-de6a1.firebaseapp.com",
    projectId: "guardiancall-de6a1",
    storageBucket: "guardiancall-de6a1.appspot.com",
    messagingSenderId: "846463338276",
    appId: "1:846463338276:web:6d1e0fc5dab4b929b115b1"
};


const firebaseApps = initializeApp(firebaseConfig);

let messagings = getMessaging(firebaseApps);

export const getTokens = async (setTokenFound) => {
    try {
        const currentToken = await getToken(messagings, {vapidKey: "BDDfneTcCTF0c3oD-iMTidtkdJLaYFtu4s1vwpuoQWzx98hzW5DJgrKY6XjsgCr9tdh1r5iEOGFAIVN8KFjTXak"});
        if (currentToken) {
            console.log('current token for client: ', currentToken);
            // await sendFcmToken(currentToken)
            setTokenFound(currentToken);
        } else {
            console.log('No registration token available. Request permission to generate one.');
            setTokenFound(false);
        }
    } catch (err) {
        console.log('An error occurred while retrieving token. ', err);
    }
}

export const onMessageListener = () => {
    return new Promise((resolve) => {
        onMessage(messagings, (payload) => {
            console.log('pauload', payload)
            resolve(payload);
        });
    });
}