import axiosConfig from "./axiosConfig";
import axios, {AxiosError, AxiosResponse} from "axios";
import ApiError from "../utils/ApiError";
import {TemplateMessageResponse} from "./types";
import {store} from "../redux/store";

export async function getAllMessageTemplates(): Promise<
  TemplateMessageResponse[]
> {
  try {
    const response = await axiosConfig.get<AxiosResponse>(
      "/admin/message-templates"
    );
    return response.data.data as TemplateMessageResponse[];
  } catch (e) {
    const err = e as Error | AxiosError;
    throw new ApiError(err);
  }
}

type templateTypeOptions = "text" | "html" | "audio";

export async function getAllMessageTemplatesByType(
  type: templateTypeOptions
): Promise<TemplateMessageResponse[]> {
  try {
    const response = await axiosConfig.get<AxiosResponse>(
      `/admin/message-templates?filter[type][eq]=${type}`
    );
    return response.data.data as TemplateMessageResponse[];
  } catch (e) {
    const err = e as Error | AxiosError;
    throw new ApiError(err);
  }
}

export async function createMessageTemplate(
  text: string,
  category: string,
  name: string,
  title: string
): Promise<void> {
  try {
    const body = {
      title,
      text,
      type: "text",
      question: 0,
      category,
      name,
      organizationId: store.getState().UserSlice.user.organizationId,
    };
    await axiosConfig.post("/admin/message-templates", body);
  } catch (e) {
    const err = e as Error | AxiosError;
    throw new ApiError(err);
  }
}

export async function createAudioMessageTemplate(
  path: string,
  name: string
): Promise<void> {
  try {
    const body = {
      type: "audio",
      question: 0,
      name,
      category: "custom",
      filePath: path,
      organizationId: store.getState().UserSlice.user.organizationId,
    };
    await axiosConfig.post("/admin/message-templates", body);
  } catch (e) {
    const err = e as Error | AxiosError;
    throw new ApiError(err);
  }
}

export async function createEmailMessageTemplate(
  text: string,
  category: string,
  name: string,
  title: string
): Promise<void> {
  try {
    const body = {
      title,
      text,
      type: "html",
      question: 0,
      category,
      name,
      organizationId: store.getState().UserSlice.user.organizationId,
    };
    await axiosConfig.post("/admin/message-templates", body);
  } catch (e) {
    const err = e as Error | AxiosError;
    throw new ApiError(err);
  }
}

export async function deleteMessageTemplate(id: number): Promise<void> {
  try {
    await axiosConfig.delete(`/admin/message-templates/${id}`);
  } catch (e) {
    const err = e as Error | AxiosError;
    throw new ApiError(err);
  }
}

export async function updateMessagetemplate(
  id: number,
  text: string,
  name: string,
  title: string
): Promise<void> {
  try {
    const body = {
      text,
      name,
      title,
    };
    await axiosConfig.patch(`/admin/message-templates/${id}`, body);
  } catch (e) {
    const err = e as Error | AxiosError;
    throw new ApiError(err);
  }
}

export async function createMessageForAll(
  text: string,
  type: string
): Promise<void> {
  try {
    const body = {
      text,
      type,
      question: 0,
    };
    await axiosConfig.post("/admin/devices/all/messages", body);
  } catch (e) {
    const err = e as Error | AxiosError;
    throw new ApiError(err);
  }
}

export async function createMessageByDeviceId({
  text,
  type,
  deviceId,
  messageTemplateId,
}: {
  text?: string;
  type?: string;
  deviceId: number;
  messageTemplateId?: number;
}): Promise<void> {
  try {
    let body = {} as any;
    if (messageTemplateId) {
      body = { messageTemplateId };
    } else {
      if (text && type) {
        body = {
          text,
          type,
          question: 0,
        };
      } else {
        throw new Error("text and type are required");
      }
    }
    await axiosConfig.post(`/admin/devices/${deviceId}/messages`, body);
  } catch (e) {
    const err = e as Error | AxiosError;
    throw new ApiError(err);
  }
}

export async function createMessageByDeviceIdsArray({
  text,
  type,
  devicesIds,
  messageTemplateId,
}: {
  text?: string;
  type?: string;
  devicesIds: number[];
  messageTemplateId?: number;
}): Promise<void> {
  try {
    let body = {} as any;
    const filters = {
      id: {
        or: devicesIds,
      },
    };
    if (messageTemplateId) {
      body = { messageTemplateId, filters };
    } else {
      if (text && type) {
        body = {
          text,
          type,
          question: 0,
          filters,
        };
      } else {
        throw new Error("text and type are required");
      }
    }
    await axiosConfig.post("/admin/devices/all/messages", body);
  } catch (e) {
    const err = e as Error | AxiosError;
    throw new ApiError(err);
  }
}


export async function sendMessageToAll(data: {
      message?: string;
      type?: string;
      group_id: any;
}): Promise<void> {
  try {
    return await axiosConfig.post("/admin/send-message-to-all", data)
  } catch (e) {
    const err = e as Error | AxiosError;
    throw new ApiError(err);
  }
}


export async function getAllCustomTemplates(): Promise<
  TemplateMessageResponse[]
> {
  try {
    const response = await axiosConfig.get<AxiosResponse>(
      "/admin/message-templates?filter[category][eq]=custom"
    );
    return response.data.data as TemplateMessageResponse[];
  } catch (e) {
    const err = e as Error | AxiosError;
    throw new ApiError(err);
  }
}

export async function getAllStandardTemplates(): Promise<
  TemplateMessageResponse[]
> {
  try {
    const response = await axiosConfig.get<AxiosResponse>(
      "/admin/message-templates?filter[category][not]=custom"
    );
    return response.data.data as TemplateMessageResponse[];
  } catch (e) {
    const err = e as Error | AxiosError;
    throw new ApiError(err);
  }
}

interface PaginationResponse {
  data: TemplateMessageResponse[];
  numberOfTotalPages: number;
}

export async function getAllCustomTemplatesForPagination(
  limit: number,
  page: number
): Promise<PaginationResponse> {
  // `/admin/message-templates?filter[category][eq]=custom&limit=${limit}&page=${page}`

  try {
    const response = await axiosConfig.get<any>(
      `/admin/message-templates?limit=${limit}&page=${page}`
    );
    const data = response.data.data as TemplateMessageResponse[];
    const numberOfTotalPages = response.data.meta.total;
    return { data, numberOfTotalPages };
  } catch (e) {
    const err = e as Error | AxiosError;
    throw new ApiError(err);
  }
}

export async function getAllStandardTemplatesForPagination(
  limit: number,
  page: number
): Promise<PaginationResponse> {
  //       `/admin/message-templates?filter[category][not]=custom&limit=${limit}&page=${page}`
  try {
    const response = await axiosConfig.get<any>(
      `/admin/message-templates?filter[status][eq]=1&limit=${limit}&page=${page}`
    );
    const data = response.data.data as TemplateMessageResponse[];
    const numberOfTotalPages = response.data.meta.total;
    return { data, numberOfTotalPages };
  } catch (e) {
    const err = e as Error | AxiosError;
    throw new ApiError(err);
  }
}

export async function getLowBatteryPrestoredMessage(): Promise<TemplateMessageResponse> {
  try {
    const res = await axiosConfig.get<any>(
      "/admin/message-templates?filter[category][eq]=battery-low"
    );
    return res.data.data[0] as TemplateMessageResponse;
  } catch (e) {
    const err = e as Error | AxiosError;
    throw new ApiError(err);
  }
}

export async function createMessageForAllLowBatteryDevices(): Promise<void> {
  try {
    const template = await getLowBatteryPrestoredMessage();
    const text = template
      ? template.text
      : "Your device has less than 30% battery, please put it to charge";
    const body = {
      text,
      type: "text",
      question: 0,
      filters: {
        battery: {
          lt: 30,
        },
      },
    };
    await axiosConfig.post("/admin/devices/all/messages", body);
  } catch (e) {
    const err = e as Error | AxiosError;
    throw new ApiError(err);
  }
}

export async function createLowBatteryMessageForDeviceId(
  deviceId: number
): Promise<void> {
  try {
    const template = await getLowBatteryPrestoredMessage();
    const text = template
      ? template.text
      : "Your device has less than 30% battery, please put it to charge";
    await createMessageByDeviceId({ text, type: "text", deviceId });
  } catch (e) {
    const err = e as Error | AxiosError;
    throw new ApiError(err);
  }
}

export async function getAudioUploadUrl(): Promise<{
  preSignedUrl: string;
  preSignedUrlMp3: string;
  path: string;
}> {
  try {
    const response = await axiosConfig.get<AxiosResponse>(
      "/admin/audio-upload-url"
    );
    return response.data.data;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function getMessageTemplateText(): Promise<
  TemplateMessageResponse[]
> {
  try {
    const response = await axiosConfig.get<AxiosResponse>(
      "/admin/message-templates?filter[type][eq]=text"
    );
    return response.data.data as TemplateMessageResponse[];
  } catch (e) {
    const err = e as Error | AxiosError;
    throw new ApiError(err);
  }
}

export async function getMessageTemplateAudio(): Promise<
  TemplateMessageResponse[]
> {
  try {
    const response = await axiosConfig.get<AxiosResponse>(
      "/admin/message-templates?filter[type][eq]=audio"
    );
    return response.data.data as TemplateMessageResponse[];
  } catch (e) {
    const err = e as Error | AxiosError;
    throw new ApiError(err);
  }
}

export async function getMessageTemplateHtml(): Promise<
  TemplateMessageResponse[]
> {
  try {
    const response = await axiosConfig.get<AxiosResponse>(
      "/admin/message-templates?filter[type][eq]=html"
    );
    return response.data.data as TemplateMessageResponse[];
  } catch (e) {
    const err = e as Error | AxiosError;
    throw new ApiError(err);
  }
}

export async function uploadAudioToS3(
  preSignedUrl: string,
  blobAudio: Blob,
  isMp3?: boolean
): Promise<AxiosResponse<any, any>> {
  try {
    const response = await axios.put(preSignedUrl, blobAudio, {
      headers: { "Content-Type": isMp3 ? "audio/mp3" : "audio/amr" },
    });
    return response;
  } catch (e) {
    const err = e as Error | AxiosError;
    throw new ApiError(err);
  }
}

export async function getUdioUrlByFilePath(
  filePath: string
): Promise<{ audioUrl: string }> {
  try {
    const response = await axiosConfig.get<AxiosResponse>(
      `/admin/audio-template?path=${filePath}`
    );
    return response.data.data as { audioUrl: string };
  } catch (e) {
    const err = e as Error | AxiosError;
    throw new ApiError(err);
  }
}

export async function getAudioUploadUrlForMessage(
  deviceId: number
): Promise<{ preSignedUrl: string; messageId: string }> {
  try {
    const response = await axiosConfig.get<AxiosResponse>(
      `/admin/devices/${deviceId}/messages/audio-upload-url`
    );
    return response.data.data;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function confirmAudioUpload(
  deviceId: number,
  messageId: string
): Promise<void> {
  try {
    await axiosConfig.post(
      `/admin/devices/${deviceId}/messages/${messageId}/upload-confirmed`
    );
  } catch (e) {
    const err = e as Error | AxiosError;
    throw new ApiError(err);
  }
}

export async function reportUploadError(
  deviceId: number,
  messageId: string
): Promise<void> {
  try {
    await axiosConfig.delete(
      `/admin/devices/${deviceId}/messages/${messageId}`
    );
  } catch (e) {
    const err = e as Error | AxiosError;
    throw new ApiError(err);
  }
}

export async function sendAudioTemplate(deviceId: number, filePath: string) {
  try {
    const body = { filePath };
    await axiosConfig.post(
      `/admin/devices/${deviceId}/messages/send-audio-template`,
      body
    );
  } catch (e) {
    const err = e as Error | AxiosError;
    throw new ApiError(err);
  }
}

export async function getAudioUploadUrlForMultipleDevices(
  devicesIds: number[]
): Promise<{ preSignedUrl: string; messagesIds: string[] }> {
  try {
    const body = {
      filters: {
        id: {
          or: devicesIds,
        },
      },
    };
    const response = await axiosConfig.post(
      "/admin/devices/all/audioMessages/audio-upload-url",
      body
    );
    return response.data.data;
  } catch (e) {
    const err = e as Error | AxiosError;
    throw new ApiError(err);
  }
}

export async function confirmAudioUploadForMultipleDevices(
  messagesIds: string[]
): Promise<void> {
  try {
    const body = {
      messagesIds,
    };
    await axiosConfig.post(
      "/admin/devices/all/audioMessages/confirm-audio-upload",
      body
    );
  } catch (e) {
    const err = e as Error | AxiosError;
    throw new ApiError(err);
  }
}

export async function reportUploadErrorForMultipleDevices(
  messagesIds: string[]
): Promise<void> {
  try {
    const body = {
      messagesIds,
    };
    await axiosConfig.post("admin/devices/all/audioMessages", body);
  } catch (e) {
    const err = e as Error | AxiosError;
    throw new ApiError(err);
  }
}

export async function sendAudioTemplateForMultipleDevices(
  devicesIds: number[],
  filePath: string
): Promise<void> {
  try {
    const body = {
      filters: {
        id: {
          or: devicesIds,
        },
      },
      filePath,
    };
    await axiosConfig.post(
      "admin/devices/all/audioMessages/send-audio-template",
      body
    );
  } catch (e) {
    const err = e as Error | AxiosError;
    throw new ApiError(err);
  }
}
