import * as React from "react";

import { MenuHeader } from "../Atomics";
import CIcon from "@coreui/icons-react";
import { cilPeople } from "@coreui/icons";
import { CSpinner } from "@coreui/react";
import "./StaffList.css";
import DeviceRow from "./DeviceRow";

// interfaces
import { User } from "../types";
import { useState } from "react";
import StaffView from "./StaffView";
import HandleUIError from "../../utils/HandleUIError";
import { userView } from "../../services/groupService";

const StaffList: React.FC<any> = ({ users, loading, refreshUsers, roleType }) => {
  const [isPreviousAction, setIsPreviousAction] = React.useState<boolean>(false);
  const [isView, setIsView] = useState<any>(null)

  const handleView = (user: any) => {
    setIsView(user)
    viewUserInfo(user)
  }

  React.useEffect(() => {
    console.log("userssssss", users)
  }, [])


  async function viewUserInfo(user: any) {
    try {
      const res: any = await userView(user?.id);
      setIsView(res?.data?.data)
    } catch (e) {
      setIsView(false)
      HandleUIError(e);
    }
  }



  return (
    <>
      <div className="" style={{ overflowX: "auto" }}>
        <div>

        </div>
        <MenuHeader className="d-flex pt-3 pb-3 table-size">
          <CIcon
            icon={cilPeople}
            size="xl"
            className="staff-icon section-width-1"
          />
          <p className="text-color section-width-2 d-block d-sm-none justify-content-center">
            Users
          </p>
          <p className="text-color section-width-2 d-none d-sm-block justify-content-center">
            User
          </p>
          <p className="text-color section-width-3 d-none d-sm-block justify-content-center">
            Student Id
          </p>
          <p className="text-color section-width-3 d-none d-sm-block justify-content-center">
            Device
          </p>
          <p className="text-color section-width-4 d-none d-sm-block justify-content-center">
            Battery
          </p>
          <p className="text-color section-width-5 d-none d-sm-block justify-content-center">
            Last connected
          </p>
          <p className="text-color section-width-6 d-none d-sm-block">
            Registration
          </p>
          <p className="text-color section-width-6 d-none d-sm-block">
            Device IMEI
          </p>
          <p className="text-color section-width-7 d-none d-sm-block"></p>
        </MenuHeader>

        {!loading ? (
          <>
            {users?.length > 0 ? (
              users?.map((user: User, index: string) => {
                return (
                  <DeviceRow
                    user={user}
                    roleType={roleType}
                    key={index}
                    refreshUsers={refreshUsers}
                    setPreviousAction={setIsPreviousAction}
                    previousAction={isPreviousAction}
                    handleView={handleView}
                  />
                );
              })
            ) : (
              <h5 className="text-light text-center my-3">Not Data Found</h5>
            )}
          </>
        ) : (
          <div className="spinner">
            <CSpinner variant="grow" />
          </div>
        )}


        {isView && (
          <StaffView
            visible={Boolean(isView)}
            closeModal={() => setIsView(null)}
            isView={isView}
          />
        )}
      </div >
    </>
  );
};

export default React.memo(StaffList);
