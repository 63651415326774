import * as React from "react";
import "../staff/NewStaff.scss";
import "./NewRole.scss"
import HandleUIError from "../../utils/HandleUIError";
import toast from "react-hot-toast";

//components
import {
  Modal,
  Input,
  Button,
  Spinner,
  IconButton,
} from "../Atomics";
import { cilX } from "@coreui/icons";
import {saveCsv, saveParents} from "../../services/rolesService";
import {useState} from "react";
import {useParams} from "react-router-dom";
import s3 from "../../utils/aws-config";

interface Props {
  visible: boolean;
  closeModal: () => void;
  refreshParents: () => void;
}

const NewRole: React.FC<Props> = ({ visible, closeModal, refreshParents}) => {
  const params: any = useParams()
  const [name, setName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [phone, setPhone] = React.useState<string>("");
  const [csvFile, setCsvFile] = useState(null)
  const [addParentLoading, setAddParentLoading] = React.useState<boolean>(false);

  const handleInputName = (e: any) => {
    setName(e.target.value);
  };
  
  const handleInputMail = (e: any) => {
    setEmail(e.target.value);
  };
  
  const handleInputPhone = (e: any) => {
    setPhone(e.target.value);
  };

  const handleFileUpload = async (file: any) => {
    return new Promise((resolve, reject) => {
      const params = {
        Bucket: "callguardian",
        Key: file.name,
        Body: file,
        ContentType: file.type,
        ContentDisposition: "inline",
      };

      s3.upload(params, (err: any, data: any) => {
        if (err) {
          console.error("Error uploading file:", err);
          reject(err);
        } else {
          console.log("File uploaded successfully:", data.Location);
          resolve(data.Location);
        }
      });
    });
  };

  const handleFileChange = (event: any) => {
    setCsvFile(event.target.files[0]);
  };

  async function addParent(file: any): Promise<void> {
    setAddParentLoading(true);
      try {
        if(!file) {
          toast.error("Please Add Csv")
          setAddParentLoading(false);
        } else {
          const uploadCsv = file ? await handleFileUpload(file) : '';
          await saveCsv(uploadCsv, params?.id);
          toast.success("Users Uploaded Successfully");
          await refreshParents();
          setAddParentLoading(false);
          onCloseModal();
        }
      } catch (e) {
        setAddParentLoading(false);
        HandleUIError(e);
      }
  }

  function onCloseModal() {
    setName("");
    setEmail("");
    setPhone("");
    closeModal();
  }

  return (
    <Modal visible={visible} onPressOut={onCloseModal}>
      <div className="new-staff-container h-auto">
        <div className="new-staff-header">
          <p className="text-light py-2 ps-4 fs-5">Add Csv</p>
          <IconButton icon={cilX} className="me-3" onClick={closeModal} />
        </div>
      
          <div className="p-3 px-4">
            <p className="text-light fs-5 pt-3 mb-3">
              User List
            </p>
              <input
                  type="file"
                  className="form-control"
                  accept=".csv"
                  onChange={handleFileChange}
              />

            <Button
              disabled={addParentLoading}
              className="mt-7 button-add px-4 mb-3"
              onClick={() => addParent(csvFile)}
            >
              {!addParentLoading ? "Upload Csv" : <Spinner />}
            </Button>
          </div>
      </div>
    </Modal>
  );
};

export default NewRole;
