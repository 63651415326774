import axiosConfig from "./axiosConfig";

import { AxiosError, AxiosResponse } from "axios";

import ApiError from "../utils/ApiError";

export async function registerFcmToken(fcmToken: string): Promise<void> {
  try {
    await axiosConfig.post<AxiosResponse>("me/register-fcm", {
      token: fcmToken,
    });
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function unregisterFcmToken(): Promise<void> {
  try {
    await axiosConfig.post<AxiosResponse>("me/register-fcm", {
      token: null,
    });
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}
