import * as React from "react";
import "./Atomics.scss";

interface Props {
  className?: string;
}

const MenuHeader: React.FC<Props> = ({ children, className }) => {
  return <div className={`menu-header ${className}`}>{children}</div>;
};

export default MenuHeader;
