import * as React from "react";
import "../staff/NewStaff.scss";
import "./NewEmergency.scss";
import HandleUIError from "../../utils/HandleUIError";
import toast from "react-hot-toast";

//components
import { Modal, Input, Button, Spinner, IconButton } from "../Atomics";
import { cilX } from "@coreui/icons";
import { editEmergency } from "../../services/emergencyService";
import { createActivityReq } from "../../services/activityLogService";
import { editEmergencyRelation } from "../../services/emergencyRelationService";

interface DataParents {
  parentId: number;
  name: string | any;
}

interface Props {
  visible: boolean;
  closeModal: () => void;
  refreshParents: () => void;
  parentData: DataParents;
}

const EditEmergencyRelation: React.FC<Props> = ({
  visible,
  closeModal,
  refreshParents,
  parentData,
}) => {
  const [name, setName] = React.useState<string>(parentData.name);
  const [addParentLoading, setAddParentLoading] =
    React.useState<boolean>(false);

  const handleInputName = (e: any) => {
    setName(e.target.value);
  };



  async function changeParent(
    parentId: number,
    name: string
  ): Promise<void> {
    setAddParentLoading(true);
    try {
      if (name === "" || name === null) {
        toast.error("Name cannot be empty");
        setAddParentLoading(false);
      } else {
        await editEmergencyRelation(parentId, name);
        await refreshParents();
        toast.success("Emergency Contact Relation modified");
        setAddParentLoading(false);
        onCloseModal();
        await createActivityReq('Emergency Contact Relation Updated Successfully')
      }
    } catch (e) {
      setAddParentLoading(false);
      HandleUIError(e);
    }
  }

  function onCloseModal() {
    closeModal();
  }

  return (
    <Modal visible={visible} onPressOut={onCloseModal}>
      <div className="new-staff-container h-auto">
        <div className="new-staff-header">
          <p className="text-light py-2 ps-4 fs-5">Edit Emergency Contact</p>
          <IconButton icon={cilX} className="me-3" onClick={closeModal} />
        </div>

        <div className="">
          <p className="text-light fs-5 ms-3 pt-3">Name</p>
          <Input
            value={name}
            className="mt-2 mb-3 ms-3 search-phone-input"
            onChange={handleInputName}
            autoFocus
          />
          <p className="text-light fs-5 ms-3 pt-3">Phone Number</p>

          <Button
            disabled={addParentLoading}
            className="mt-7 button-add px-4 mb-3"
            onClick={() =>
              changeParent(parentData.parentId, name)
            }
          >
            {!addParentLoading ? "Save changes" : <Spinner />}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default EditEmergencyRelation;
