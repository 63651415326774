import * as React from "react";
import "./Atomics.scss";

interface Props {
  horizontalSpace?: number | string;
}

const Divider: React.FC<Props> = ({ horizontalSpace }) => {
  const style: React.CSSProperties = {
    marginLeft: horizontalSpace ? horizontalSpace : 0,
    marginRight: horizontalSpace ? horizontalSpace : 0,
  };

  return <div style={style} className="divider" />;
};
export default Divider;
