import * as React from "react";
import "./Staff.scss";

//components
import { Pagination } from "../components";
import { NewMessage } from "../components/messages";
import { PageContainer } from "../components/Atomics";
import CIcon from "@coreui/icons-react";
import { cilPlus } from "@coreui/icons";
import HandleUIError from "../utils/HandleUIError";
// types
import { Roles } from "../components/types";
// services
import { getEmergency, searchEmergency } from "../services/emergencyService";
import EmergencyList from "../components/emergency/EmergencyList";
import NewEmergency from "../components/emergency/NewEmergency";
import useDebounce from "../hooks/useDebounce";
import {useEffect} from "react";
import usePermission from "../hooks/usePermission";
import { getEmergencyRelation } from "../services/emergencyRelationService";
import EmergencyListRelation from "../components/emergency/EmergencyListRelation";
import NewEmergencyRelation from "../components/emergency/NewEmergencyRelation";

const EmergencyContactsRelation: React.FC = () => {
  const { checkPermission } = usePermission()
  const [visibleNewParent, setVisibleNewParent] = React.useState(false);
  const [currentParents, setCurrentParents] = React.useState<any>([]);
  const [parents, setParents] = React.useState<any>();
  const [numberOfTotalParents, setNumberOfTotalParents] = React.useState(0);
  const [loading, setLoading] = React.useState<boolean>(true);
  const MAX_SIZE_ORGANIZATIONS = 10;
  const [numberOfPages, setNumberOfPages] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [searchValue, setSearchValue] = React.useState<string>("");

  const debouncedSearch = useDebounce(searchValue, 300);

  // function searchEmergencyDebounced() {
  //   searchEmergency(parentsPageSize, 1, searchValue)
  //     .then((parentsFound) => {
  //       setCurrentPage(1);
  //       // @ts-ignore
  //       setCurrentParents(parentsFound.data);
  //       setPageNumbers(parentsFound.numberOfTotalParents / parentsPageSize);
  //       setNumberOfTotalParents(parentsFound.numberOfTotalParents);
  //     })
  //     .catch((e) => {
  //       HandleUIError(e);
  //     });
  // }
  //
  // React.useEffect(
  //   function () {
  //     if (debouncedSearch) {
  //       searchEmergencyDebounced();
  //     } else {
  //       searchEmergency(parentsPageSize, 1, searchValue)
  //         .then((parentsFound) => {
  //           setCurrentPage(1);
  //           // @ts-ignore
  //           setCurrentParents(parentsFound.data);
  //           setPageNumbers(parentsFound.numberOfTotalParents / parentsPageSize);
  //           setNumberOfTotalParents(parentsFound.numberOfTotalParents);
  //         })
  //         .catch((e) => {
  //           HandleUIError(e);
  //         });
  //     }
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [debouncedSearch]
  // );

  const reFetch = (limit: any, page: any) => {
    setLoading(true);
    getEmergencyRelation(limit, page)
        .then((res: any) => {
          console.log('rows', res)
          setCurrentParents(res?.rows);
          setNumberOfPages(res?.count / MAX_SIZE_ORGANIZATIONS);
        })
        .catch((e) => {
          HandleUIError(e);
        })
        .finally(() => {
          setLoading(false)
        })
  }

  React.useEffect(() => {
    reFetch(MAX_SIZE_ORGANIZATIONS, currentPage);
  }, []);


  React.useEffect(() => {
    reFetch(MAX_SIZE_ORGANIZATIONS, currentPage)
  }, [currentPage])


  function onChangeSearchValue(e: any) {
    setSearchValue(e.target.value);
  }

  console.log("currentParents", currentParents);

  //this function is treggered by pagination
  /*async function refreshParents() {
    setLoading(true);
    try {
      let parentsFound: any = await searchEmergency(
        parentsPageSize,
        currentPage,
        searchValue
      );
      setCurrentParents(parentsFound.data);
      setPageNumbers(parentsFound.numberOfTotalParents / parentsPageSize);
      setNumberOfTotalParents(parentsFound.numberOfTotalParents);
      setLoading(false);
    } catch (e) {
      HandleUIError(e);
      setLoading(false);
    }
  }*/

  function onPressAdd() {
    setVisibleNewParent(!visibleNewParent);
  }

  return (
    <div className="bg-main pb-5 over-flow-auto h-100">
      <PageContainer className="page-container-staff d-flex flex-column justify-content-start pb-3 mx-4">
        <div className="d-flex flex-custom-row flex-column staff-menu">
          <div className="d-flex justify-content-between w-100 align-items-center flex-dir-column">
            <div className="col-auto me-auto">
              <h3 className="text-light me-auto">Emergency Contacts Relation</h3>
            </div>
            <div className="d-flex align-self-end mt-3 mt-xxl-0 buttons-right wrap">
              {checkPermission("Create and Edit Emergency Contact") && (
                  <div
                      className="add-icon-container mg-lt add-icon-staff"
                      onClick={onPressAdd}
                  >
                    <CIcon icon={cilPlus} size="xl" className="add-icon" />
                  </div>
              )}
            </div>
          </div>
        </div>
        <div>
          <EmergencyListRelation
            parents={currentParents}
            loading={loading}
            refreshParents={() => reFetch(MAX_SIZE_ORGANIZATIONS, currentPage)}
          />
        </div>
        <div className="py-3">
          {numberOfPages > 1 ? (
              <Pagination
                  numberOfPages={numberOfPages}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
              />
          ) : (
              <></>
          )}
        </div>
      </PageContainer>
      <NewEmergencyRelation
        visible={visibleNewParent}
        closeModal={onPressAdd}
        refreshParents={() => reFetch(MAX_SIZE_ORGANIZATIONS, currentPage)}
      />
      <NewMessage />
    </div>
  );
};

export default EmergencyContactsRelation;
