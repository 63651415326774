import * as React from "react";
import "./NewTemplate.scss";

import { useReactMediaRecorder } from "react-media-recorder";

//components
import { cilTrash, cilMic } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { Button, IconButton, Spinner, Input } from "../Atomics";
import {
  ChronometerAudioMessage,
  AudioPlayerCustom as AudioPlayer,
  DropZone,
} from ".";

//services
import { convertMp3ToArm, audioIsInFormat } from "../../utils/formatConverters";
import {
  getAudioUploadUrl,
  uploadAudioToS3,
  createAudioMessageTemplate,
  sendAudioTemplate,
  getAudioUploadUrlForMessage,
  confirmAudioUpload,
  reportUploadError,
  getAudioUploadUrlForMultipleDevices,
  confirmAudioUploadForMultipleDevices,
  reportUploadErrorForMultipleDevices,
  sendAudioTemplateForMultipleDevices,
} from "../../services/messagesService";
import HandleUIError from "../../utils/HandleUIError";
import toast from "react-hot-toast";
import { TemplateMessageResponse } from "../../services/types";
import s3 from "../../utils/aws-config";

interface Props {
  isTemplate?: boolean;
  closeModal?: () => void;
  refreshTemplates?: () => Promise<void>;
  audioTemplate?: TemplateMessageResponse;
  deviceId?: number | number[] | null;
}

const AudioRecorder: React.FC<Props> = ({
  isTemplate,
  closeModal,
  refreshTemplates,
  audioTemplate,
  deviceId,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [name, setName] = React.useState("");
  const [file, setFile] = React.useState<any>();
  const [loadingSendTemplate, setLoadingSendTemplate] = React.useState(false);

  function onClose() {
    setName("");
    setFile("");
    clearBlobUrl();
    closeModal && closeModal();
    // window.location.reload();
  }

  function onChangeFile(file: any) {
    setFile(file);
  }

  const { status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl } =
    useReactMediaRecorder({
      audio: true,
    });

  function onChangeName(e: any) {
    setName(e.target.value);
  }

  async function saveAudioRecording() {
    setLoading(true);
    await stopRecording();
    try {
      if (mediaBlobUrl) {
        const { preSignedUrl, preSignedUrlMp3, path } =
          await getAudioUploadUrl();
        const audioBlob = await convertMp3ToArm(mediaBlobUrl);
        if (audioBlob) {
          await uploadAudioToS3(preSignedUrl, audioBlob);
          const audioBlobMp3 = new Blob([audioBlob]);
          await uploadAudioToS3(preSignedUrlMp3, audioBlobMp3, true);
          await createAudioMessageTemplate(path, name);
        }
      } else {
        toast.error("Error to upload audio file");
      }
      setLoading(false);
      onClose();
      refreshTemplates && refreshTemplates();
    } catch (e) {
      setLoading(false);
      HandleUIError(e);
    }
  }

  async function sendAudioRecording() {
    setLoading(true);
    try {
      console.log('mediaBlobUrl', mediaBlobUrl)
      if (mediaBlobUrl && deviceId) {
        if (typeof deviceId === "number") {
          //send individual audio message
          const { preSignedUrl, messageId } = await getAudioUploadUrlForMessage(
            deviceId
          );
          const audioBlob = await convertMp3ToArm(mediaBlobUrl);
          if (audioBlob) {
            const AwsS3Response = await uploadAudioToS3(
              preSignedUrl,
              audioBlob
            );
            if (AwsS3Response.status === 200) {
              await confirmAudioUpload(deviceId, messageId);
              toast.success("Audio file sent successfully");
            } else {
              await reportUploadError(deviceId, messageId);
              toast.error("Error to upload audio file");
            }
          } else {
            toast.error("Error to upload audio file");
          }
        } else {
          //send multiple audio message
          const devicesIds = deviceId;
          const { preSignedUrl, messagesIds } =
            await getAudioUploadUrlForMultipleDevices(devicesIds);
          const audioBlob = await convertMp3ToArm(mediaBlobUrl);
          if (audioBlob) {
            const AwsS3Response = await uploadAudioToS3(
              preSignedUrl,
              audioBlob
            );
            if (AwsS3Response.status === 200) {
              await confirmAudioUploadForMultipleDevices(messagesIds);
              toast.success("Audio file sent successfully");
            } else {
              await reportUploadErrorForMultipleDevices(messagesIds);
              toast.error("Error to upload audio file");
            }
          }
        }
      } else {
        toast.error("Error to upload audio file");
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      HandleUIError(e, intercepError500);
    }
  }

  async function onPressSend() {
    if (isTemplate) {
      await saveAudioRecording();
    } else {
      await sendAudioRecording();
    }
    onClose();
  }

  const handleFileUpload = async (file: any) => {
    return new Promise((resolve, reject) => {
      const params = {
        Bucket: "callguardian",
        Key: file.name,
        Body: file,
        ContentType: file.type,
        ContentDisposition: "inline",
      };

      s3.upload(params, (err: any, data: any) => {
        if (err) {
          console.error("Error uploading file:", err);
          reject(err);
        } else {
          console.log("File uploaded successfully:", data.Location);
          resolve(data.Location);
        }
      });
    });
  };

  async function saveAudioFile() {
    setLoading(true);
    if (file) {
      // const { preSignedUrl, preSignedUrlMp3, path } = await getAudioUploadUrl();
      let audioBlob: Blob | undefined;
      if (audioIsInFormat(file.path, ".mp3")) {
        const audioBlobMp3 = new Blob([file]);
        audioBlob = await convertMp3ToArm(audioBlobMp3);
      } else {
        audioBlob = new Blob([file]);
      }
      console.log('file', file)
      if (file) {
        // let randomNumber: number = Math.floor(Math.random() * 100) + 1;
        const path: any  = await handleFileUpload(file)
        // await uploadAudioToS3(preSignedUrl, audioBlob);
        // await uploadAudioToS3(preSignedUrlMp3, audioBlob, true);
        await createAudioMessageTemplate(path, name);
      } else {
        toast.error("Error to upload audio file");
      }
    } else {
      toast.error("Error to upload audio file");
    }
    setLoading(false);
    onClose();
    refreshTemplates && refreshTemplates();
  }

  async function sendAudioFile() {
    setLoading(true);
    try {
      if (file && deviceId) {
        if (typeof deviceId === "number") {
          //send audio for a one device
          const { preSignedUrl, messageId } = await getAudioUploadUrlForMessage(
            deviceId
          );
          const audioBlob = new Blob([file]);
          const AwsS3Response = await uploadAudioToS3(preSignedUrl, audioBlob);
          if (AwsS3Response.status === 200) {
            await confirmAudioUpload(deviceId, messageId);
            toast.success("Audio file sent successfully");
          } else {
            await reportUploadError(deviceId, messageId);
            toast.error("Error to upload audio file");
          }
        } else {
          //send audio for a multiple devices
          const devicesIds = deviceId;
          const { preSignedUrl, messagesIds } =
            await getAudioUploadUrlForMultipleDevices(devicesIds);
          const audioBlob = new Blob([file]);
          const AwsS3Response = await uploadAudioToS3(preSignedUrl, audioBlob);
          if (AwsS3Response.status === 200) {
            await confirmAudioUploadForMultipleDevices(messagesIds);
            toast.success("Audio file sent successfully");
          } else {
            await reportUploadErrorForMultipleDevices(messagesIds);
            toast.error("Error to upload audio file");
          }
        }
      } else {
        toast.error("Error to upload audio file");
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      HandleUIError(e, intercepError500);
    }
  }

  async function onPressSendAudioFile() {
    if (isTemplate) {
      await saveAudioFile();
    } else {
      await sendAudioFile();
    }
    onClose();
  }

  async function onPressSendAudioTemplate() {
    setLoadingSendTemplate(true);
    try {
      if (deviceId) {
        if (audioTemplate && audioTemplate.filePath) {
          if (typeof deviceId === "number") {
            await sendAudioTemplate(deviceId, audioTemplate.filePath);
            toast.success("Message sent successfully");
          } else {
            const devicesIds = deviceId;
            await sendAudioTemplateForMultipleDevices(
              devicesIds,
              audioTemplate.filePath
            );
            toast.success("Message sent successfully");
          }
        } else {
          toast.error("Error to send audio");
        }
      }
      setLoadingSendTemplate(false);
      onClose();
    } catch (e) {
      setLoadingSendTemplate(false);
      HandleUIError(e, intercepError500);
    }
    onClose();
  }

  function showTrash() {
    if (status === "recording") {
      return true;
    }
    if (mediaBlobUrl) {
      return true;
    }
    return false;
  }

  //TODO: remove this function when the problem are solved
  function intercepError500(statusCode: number) {
    if (statusCode === 500) {
      toast.success("Message sent successfully");
    }
  }

  return (
    <div>
      {isTemplate && (
        <>
          <div className="d-flex align-items-center">
            <p className="text-light fs-5 m-0 ms-3">Template name: </p>
            <Input
              className="mt-2 w-50 m-0 ms-3 text-light"
              onChange={onChangeName}
              autoFocus
            />
          </div>
        </>
      )}
      {audioTemplate ? (
        <div>
          <p className="fs-5 ms-3 mt-3 text-center">
            Template: {audioTemplate.name}
          </p>
          <div className="w-100 d-flex pt-3 justify-content-end">
            <Button
              onClick={onPressSendAudioTemplate}
              className="px-4 me-3 mt-5"
            >
              {loadingSendTemplate ? <Spinner /> : "Send"}
            </Button>
          </div>
        </div>
      ) : (
        <>
          {file ? (
            <div className="d-flex flex-column align-items-center mt-4">
              <p className="mb-4">File: {file.name}</p>
              <div className="d-flex w-100 justify-content-end pe-3">
                <Button className="px-4" onClick={onPressSendAudioFile}>
                  {loading ? <Spinner /> : isTemplate ? "Save" : "Send"}
                </Button>
              </div>
            </div>
          ) : (
            <>
              <div
                className={
                  "d-flex w-100 justify-content-center align-items-center py-5"
                }
              >
                {status !== "recording" && !mediaBlobUrl && (
                  <div className="d-flex flex-column align-items-center">
                    {!isTemplate && (
                      <>
                        <Button className="mt-2 w-100" onClick={startRecording}>
                          START RECORDING
                          <CIcon className="ms-1" size="xl" icon={cilMic} />
                        </Button>
                        <p className="my-2">OR</p>
                      </>
                    )}
                    <DropZone onChangeFile={onChangeFile} />
                  </div>
                )}
                {showTrash() && (
                  <IconButton
                    icon={cilTrash}
                    size="xxl"
                    onClick={clearBlobUrl}
                  />
                )}
                {status === "recording" && (
                  <div className="d-flex align-items-center ms-4">
                    <div className="recording-status p-0 m-0 me-1" />
                    <ChronometerAudioMessage className="audio-time-text m-0 p-0" />
                  </div>
                )}
                {mediaBlobUrl && (
                  <AudioPlayer
                    src={mediaBlobUrl}
                    className="audio-player-container"
                  />
                )}
                <div className="d-flex">
                  {status === "recording" && (
                    <Button
                      className="stop-button-container m-0 p-0 me-2"
                      onClick={stopRecording}
                    >
                      <div className="icon-stop-button" />
                    </Button>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-end me-2 mb-2">
                {mediaBlobUrl && (
                  <Button className="px-4" onClick={onPressSend}>
                    {loading ? <Spinner /> : isTemplate ? "Save" : "Send"}
                  </Button>
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default AudioRecorder;
