import React, { useState } from 'react'
import gcLogoSq from "../../assets/gc_logo_square_white.svg";
import { Link, useHistory } from 'react-router-dom';
import { emailVerification } from '../../services/AuthSettingService';
import { useDispatch } from 'react-redux';
import { saveEmail } from '../../redux/reducers/resetPasswordReducer';
import toast from 'react-hot-toast';
import HandleUIError from '../../utils/HandleUIError';
import { Button, Input, Spinner } from '../../components/Atomics';


const Verification = () => {


    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)

    const history = useHistory()
    const dispatch = useDispatch()

    var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    const handleSubmit = async (e, email) => {
        e.preventDefault();

        if (email === '') {
            toast.error("Please Enter Email", {id: 25});
            return;
        }
        if (!pattern.test(email)) {
            toast.error("Please Enter Valid Email Address", {id: 26})
            return;
        }

        try {
            const res = await emailVerification({ email });
            // setLoading(false)
            if (res?.data?.status === "success") {
                dispatch(saveEmail(email))
                toast.success(res?.data?.message, {id: 27});
                history.push("/reset-password")
            }
            else if (res?.data?.status === "error") {
                toast.error(res?.data?.message, {id: 28});
            }
        } catch (e) {
            toast.error(e?.data?.message, {id: 28});
            setLoading(false);
            HandleUIError(e);
        }

    };

    return (
        <div className="login-container verifi-email">
            <div className="login-border p-4">
                <div className="">
                    <h1 className="text-center mb-4 text-color login-title">
                        <img src={gcLogoSq} alt="GuardianCall" className="img-fluid" />
                    </h1>
                    <div className="mb-3">
                        <p className="text-light mb-2">Enter your email</p>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            // onChange={changeEmail}
                            className="w-100 login-input"
                            placeholder='Enter your email here'
                        />
                    </div>

                    <Button
                        onClick={(e) => handleSubmit(e, email)}
                        className="w-100 fs-4 sign-in-button"
                        disabled={loading}
                    >
                        {loading ? <Spinner className="ms-3" /> : "Submit"}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default Verification