import * as React from "react";
import "./AlertInProgress.scss";
import moment from "moment";

import { CButton, CSpinner } from "@coreui/react";

//components
import { Spinner, UserAvatar } from "../Atomics";
import CIcon from "@coreui/icons-react";
import { cilCheckCircle } from "@coreui/icons";

//services
import {
  getAlertsInProgress,
  closeAlertInProgress,
} from "../../services/alertServices";
import { AlertResponse } from "../../services/types";
import HandleUIError from "../../utils/HandleUIError";
import ApiErrorStatusCode from "../../utils/ApiErrorStatusCode";
import { getZoneById } from "../../services/facilityService";

const AlertInProgress: React.FC = () => {
  const [currentAlert, setCurrentAlert] = React.useState<AlertResponse>();
  const [loading, setLoading] = React.useState(false);
  const [loadingClose, setLoadingClose] = React.useState(false);
  const [zoneName, setZoneName] = React.useState<string>();

  React.useEffect(() => {
    refreshAlerts().catch((e) => console.warn(e));
  }, []);

  async function refreshAlerts() {
    setLoading(true);
    try {
      const alertsResponse = await getAlertsInProgress();
      setCurrentAlert(alertsResponse[0]);
      if (alertsResponse[0]?.zoneId) {
        const zone = await getZoneById(alertsResponse[0].zoneId);
        setZoneName(zone.name);
      } else {
        setZoneName("From BackOffice");
      }
      setLoading(false);
    } catch (e) {
      HandleUIError(e);
      setLoading(false);
    }
  }

  async function closeCurrentAlert() {
    if (currentAlert) {
      setLoadingClose(true);
      try {
        await closeAlertInProgress(currentAlert.id);
        await refreshAlerts();
        setLoadingClose(false);
      } catch (e) {
        HandleUIError(e, interceptError);
        setLoadingClose(false);
      }
    }
  }

  function interceptError(errorStatusCode: number) {
    if (errorStatusCode === ApiErrorStatusCode.ConflictError) {
      window.location.reload();
    }
  }

  return (
    <div className="h-100">
      <>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center h-100">
            <CSpinner variant="grow" />
          </div>
        ) : (
          <>
            {!currentAlert ? (
              <div className="d-flex flex-column gy-sm-5 justify-content-between h-100">
                <div>
                  <div className="pt-2 pb-1 rounded-top low-battery-devices-header d-flex justify-content-between">
                    <p className="text-light fs-5 ms-4 pb-3">
                      No alerts to display
                    </p>
                  </div>
                  <div className="justify-content-between ps-4 pe-4 mt-4 d-flex flex-column">
                    <div className="align-self-center m-o p-o">
                      <CIcon
                        icon={cilCheckCircle}
                        className="check-alert"
                        width={200}
                      />
                    </div>
                  </div>
                </div>
                <p className="text-color2 text-center fs-5 mb-2 mb-sm-4">
                  Everything is fine
                </p>
              </div>
            ) : (
              <div className="d-flex flex-column gy-sm-5 justify-content-between h-100">
                <div className="pt-2 pb-1  rounded-top low-battery-devices-header d-flex justify-content-between">
                  <p className="text-light fs-5 ms-4 pb-2">Alert in Progress</p>
                  <p className="text-light fs-5  me-4  text-center">
                    Reported at: {moment(currentAlert.createdAt).calendar()}
                  </p>
                </div>
                <div className="ps-4 pe-4 mt-2 d-flex flex-column h-100 ">
                  <div className="content-custom-alert d-flex h-100 mt-2">
                    <div className="px-5" />
                    <p className="text-color2 text-center yellow-text fs-1 mt-5">
                      {currentAlert.alertType?.name}
                    </p>
                    <div className="d-flex content-center-xs">
                      <CButton
                        className="dismiss-button align-top mb-1 mb-md-0"
                        color="success"
                        variant="outline"
                        onClick={closeCurrentAlert}
                      >
                        {loadingClose ? <Spinner /> : "Close"}
                      </CButton>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between mt-3 ps-3 flex-md-row flex-column ">
                    <div className="ps-0">
                      <p className="text-color2 text-center fs-6 mt-4  mt-md-0  mb-md-4">
                        LOCATION:
                      </p>
                      <p className="text-color2 mb-4 text-center fs-2">
                        {zoneName}
                      </p>
                    </div>
                    <div className="d-flex flex-column">
                      <p className="text-color2 text-center fs-6  mb-2">
                        ACTIVATED BY:
                      </p>
                      <div className="align-self-center">
                        <UserAvatar
                          iconLetter={currentAlert.user?.firstName[0]}
                          size={3}
                        />
                      </div>
                      <p className="text-color2 text-center fs-5">
                        {currentAlert.user?.firstName +
                          " " +
                          currentAlert.user?.lastName}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </>
    </div>
  );
};

export default AlertInProgress;
