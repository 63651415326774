import * as React from "react";

import { EditorState, Editor } from "draft-js";
import "draft-js/dist/Draft.css";
import { convertToStateFromHTML } from "../utils/formatConverters";

interface Props {
  text: string;
}

const TextViewer: React.FC<Props> = ({ text }) => {
  const [editorState, setEditorState] = React.useState(
    EditorState.createWithContent(convertToStateFromHTML(text))
  );

  React.useEffect(
    function () {
      setEditorState(
        EditorState.createWithContent(convertToStateFromHTML(text))
      );
      console.log(text);
    },
    [text]
  );

  const _handleChange = () => {
    setEditorState(editorState);
  };

  return <Editor editorState={editorState} onChange={_handleChange} />;
};

export default TextViewer;
