import React, {useEffect, useState} from 'react';
import {Button, IconButton, Modal, Spinner} from "../Atomics";
import {cilTrash, cilX} from "@coreui/icons";
import {getAllGroups} from "../../services/groupService";
import HandleUIError from "../../utils/HandleUIError";
import toast from "react-hot-toast";
import {saveSchoolDetail, sendQuickAlert} from "../../services/alertServices";
import {createActivityReq} from "../../services/activityLogService";

const SendQuickAlerts = ({visible, closeModal, isEdit, refetch}) => {
    const [loading, setLoading] = useState(false);
    const [details, setDetails] = useState([
        {
            heading: '',
            description: ''
        }
    ])


    const handleAddMore = () => {
        setDetails(pre => [...pre, {heading: '', description: ''}])
    }
    const handleRemove = (data) => {
        setDetails(pre => pre?.filter(item => item !== data))
    }

    const handleHeader = (e, data) => {
        setDetails(pre => pre.map(item => item === data ? {...item, heading: e.target.value} : item))
    }

    const handleContent = (e, data) => {
        setDetails(pre => pre.map(item => item === data ? {...item, description: e.target.value} : item))
    }

    const onPressSend = async () => {
        if (!details?.length > 0) {
            toast.error('Please fill fields', {id: 35});
            return;
        }

        setLoading(true);
        try {
            const response = await saveSchoolDetail({data: details});
            console.log('response', response);
            refetch()
            toast.success('Save Successfully', {id: 36});
            closeModal();
            await createActivityReq('School Details Saved Successfully')
        } catch (e) {
            HandleUIError(e);
        }
        setLoading(false);
    }

    useEffect(() => {
        if (isEdit) {
            setDetails(isEdit)
        }
    }, [isEdit])

    console.log('details', details, isEdit)

    return (
        <>
            <Modal
                visible={visible}
                onPressOut={() => {
                    closeModal()

                }}
            >
                <div className="new-staff-container h-auto">
                    <div className="new-staff-header">
                        <p className="text-light py-2 ps-4 fs-5">
                            Save School Detail
                        </p>
                        <IconButton
                            icon={cilX} className="me-3"
                            onClick={() => {
                                closeModal()
                            }}
                        />
                    </div>

                    <div className="py-3 w-100" style={{height: 400, overflowY: 'auto'}}>
                        {details?.map((item, index) => (
                            <React.Fragment key={index}>
                                <div className="px-3 mb-3">
                                    <div className="d-flex flex-column align-items-start">
                                        <p className="text-light mb-2 d-block">Header:</p>
                                        <div className="d-flex align-items-center justify-content-between w-100">
                                            <div className="col">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    style={{
                                                        backgroundColor: '#38373d',
                                                        border: '1.5px solid #505050',
                                                        color: '#51fffe'
                                                    }}
                                                    maxLength={100}
                                                    value={item?.heading}
                                                    onChange={(e) => handleHeader(e, item)}
                                                />
                                            </div>
                                            {details?.length > 1 && (
                                                <div className="col-2 ps-2">
                                                    <button
                                                        className="btn btn-danger text-white w-100"
                                                        onClick={() => handleRemove(item)}
                                                    >
                                                        Remove
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column align-items-start mt-3">
                                        <p className="text-light mb-2 d-block">Content:</p>
                                        <textarea
                                            className="form-control"
                                            rows="3"
                                            style={{
                                                backgroundColor: '#38373d',
                                                border: '1.5px solid #505050',
                                                color: '#51fffe'
                                            }}
                                            maxLength={300}
                                            value={item?.description}
                                            onChange={(e) => handleContent(e, item)}
                                        />
                                    </div>
                                </div>
                                <div className="px-3 py-1">
                                    {details?.length > 1 && <hr />}
                                </div>
                            </React.Fragment>
                        ))}

                        <div className="py-3 px-3" onClick={handleAddMore}>
                            <button className="btn btn-success text-white w-100">
                                Add More
                            </button>
                        </div>
                        <div className="d-flex justify-content-end px-3 pb-3">
                            <Button onClick={onPressSend} disabled={loading} className="px-4">
                                {loading ? <Spinner /> : "Save"}
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default SendQuickAlerts;