import axios, { AxiosError } from "axios";
import config from "../config";
import ApiError from "../utils/ApiError";
import {
  GeocodingCoordinatesByAddressResponse,
  GeocodingServiceResponse,
  ZoneResponse,
} from "../services/types";
import axiosConfig from "./axiosConfig";

export async function getCoordinatesByDirectionString(
  adrress: string
): Promise<GeocodingServiceResponse | undefined> {
  const { geocodingBaseUrl, googleApiKey } = config.google;
  try {
    const response = await axios.get(
      `${geocodingBaseUrl}${adrress}&key=${googleApiKey}`
    );
    const geocodingData =
      response.data as GeocodingCoordinatesByAddressResponse;
    if (geocodingData.results.length === 0) {
      return undefined;
    }
    const location = geocodingData.results[0].geometry.location;
    const formatedAddress = geocodingData.results[0].formatted_address;
    const coordinates = {
      latitude: location.lat,
      longitude: location.lng,
      formatedAddress,
    };
    return coordinates;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function getAllZones(): Promise<ZoneResponse[]> {
  try {
    const response = await axiosConfig.get("/admin/zones");
    return response.data.data;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function getZoneById(zoneId: number): Promise<ZoneResponse> {
  try {
    const response = await axiosConfig.get(`/admin/zones/${zoneId}`);
    return response.data.data as ZoneResponse;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

interface ZonePoint {
  latitude: number;
  longitude: number;
  order: number;
}

export async function addZone(
  name: string,
  addressName: string,
  zonePoints: ZonePoint[]
): Promise<void> {
  try {
    const body = {
      name,
      address: addressName,
      zonePoints,
    };
    await axiosConfig.post("/admin/zones", body);
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function showHideZone(
    id: number,
    isShow: boolean,
): Promise<void> {
  try {
    const body = {
      isShow
    };
    return await axiosConfig.put(`/admin/zones/${id}`, body);
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function deleteZone(zoneId: number): Promise<void> {
  try {
    await axiosConfig.delete(`/admin/zones/${zoneId}`);
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function updateZone(
  zoneId: number,
  name: string,
  addressName: string,
  zonePoints: ZonePoint[]
): Promise<void> {
  try {
    await axiosConfig.patch(`/admin/zones/${zoneId}`, { name, address: addressName, zonePoints });
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}
