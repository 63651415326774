import * as React from "react";
import "../messages/NewTemplate.scss";

//components
import {Button, IconButton, Input, Spinner} from "../Atomics";
import { TextEditor } from "..";
import { CFormSelect } from "@coreui/react";
import {AudioPlayerCustom as AudioPlayer, AudioRecorder, ChronometerAudioMessage} from "../messages";
import { GroupButtons, ButtonForGroup } from "../Atomics/GroupButtons";
import { useReactMediaRecorder } from 'react-media-recorder';

//services
import HandleUIError from "../../utils/HandleUIError";
import {createMessageByDeviceIdsArray, sendMessageToAll} from "../../services/messagesService";
import toast from "react-hot-toast";
import { GroupResponse } from "../../services/types";
import { getAllGroups, getOneGroup } from "../../services/groupService";
import s3 from "../../utils/aws-config";
import CIcon from "@coreui/icons-react";
import {cilMic, cilTrash} from "@coreui/icons";
import {useRef, useState} from "react";
import Multiselect from "multiselect-react-dropdown";
import usePermission from "../../hooks/usePermission";
import {createActivityReq} from "../../services/activityLogService";

type messageTypeOptions = "voice" | "message" | "image";
interface MediaRecorderProps {
  status: string;
  startRecording: () => void;
  stopRecording: () => void;
  mediaBlobUrl: string;
  clearBlobUrl: () => void;
}

const ImpromptuMessage: React.FC = () => {
  const { checkPermission } = usePermission()
  const [text, setText] = React.useState("");
  const [file, setFile] = React.useState<any>(null);
  const [mediaBlob, setMediaBlob] = React.useState<any>(null);
  const { status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl } = useReactMediaRecorder({
    audio: true,
    onStop: setMediaBlob,
  }) as MediaRecorderProps;
  const [loading, setLoading] = React.useState(false);
  const [groups, setGroups] = React.useState<GroupResponse[]>();
  const [selectedGroup, setSelectedGroup] = React.useState<GroupResponse>();
  const [messageType, setMessageType] =
    React.useState<messageTypeOptions>("message");
  const [devicesIds, setDevicesIds] = React.useState<number[]>();
  const [windowSize, setWindowSize] = React.useState(getWindowSize());
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = React.useState([]);

  const fileInputRef = useRef<any>(null);

  function onPressText() {
    setMessageType("message");
  }

  function onPressAudio() {
    setMessageType("voice");
  }

  function onPressImage() {
    setMessageType("image");
  }

  function onChangeTemplate(e: any) {
    // const selectedTemplate = groups?.find(
    //   (t) => t.id.toString() === e.target.value
    // );
    console.log('selectedTemplate===', e.target.value);
    setSelectedGroup(e.target.value);
  }

  React.useEffect(
    function () {
      getTemplates();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [messageType]
  );

  /*React.useEffect(
    function () {
      getDevicesIds()
        .then((res) => setDevicesIds(res))
        .catch(HandleUIError);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedGroup]
  );*/

  React.useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  async function getTemplates() {
    try {
      setGroups([]);
      const groupsResponse: any = await getAllGroups();
      setGroups(groupsResponse);
      setOptions(groupsResponse?.map((item: any) => {
        return {
          id: item.id,
          value: item.name,
        }
      }));
    } catch (error) {
      HandleUIError(error);
    }
  }

  function onChangeText(t: string) {
    setText(t);
  }

  function handleFileChange(e: any) {
    setFile(e.target.files[0])
  }

  async function getDevicesIds() {
    let devicesIds: any;
    if (selectedGroup) {
      const groupData = await getOneGroup(selectedGroup.id);
      devicesIds = groupData.users.map((device) => device.id);
    }
    return devicesIds;
  }

  const handleFileUpload = async (file: any, type: string) => {
    return new Promise((resolve, reject) => {
      const params = {
        Bucket: "callguardian",
        Key: file?.name ? file?.name : type,
        Body: file,
        ContentType: file.type,
        ContentDisposition: "inline",
      };

      s3.upload(params, (err: any, data: any) => {
        if (err) {
          console.error("Error uploading file:", err);
          reject(err);
        } else {
          console.log("File uploaded successfully:", data.Location);
          resolve(data.Location);
        }
      });
    });
  };

  async function triggerEndpointService(msg: any) {
    setLoading(true);
    console.log('selectedGroup', selectedOptions)
    try {
      console.log('msg===', msg);
      if (selectedOptions?.length > 0 && msg) {
        let msg2 = null;
        if(messageType === "message"){
              msg2 = msg
        } else if (messageType === "image") {
              msg2 = await handleFileUpload(msg, '');
        } else {
          const blobData = await fetch(mediaBlob).then((response) => response.blob());

          // Convert the Blob data to an ArrayBuffer
          const arrayBuffer = await new Response(blobData).arrayBuffer();

          let randomNumber: number = Math.floor(Math.random() * 100) + 1;
          // Convert the ArrayBuffer to an MP3 file
          // @ts-ignore
          const blobs: any = new Blob([arrayBuffer], { type: 'audio/mpeg' });
          // const url = URL.createObjectURL(blobs);

          console.log('blobs==', blobs)

          // const blob = {
          //   name: `recorded-${randomNumber}-media.mp3`,
          //   type: 'audio/mpeg',
          //   // blob: mediaBlob
          // }
          msg2 = await handleFileUpload(blobs, `recorded-${randomNumber}-media.mp3`);
          if(msg2) {
            clearBlobUrl()
          }
        }

        const data: any = { message: msg2, type: messageType, group_ids: selectedOptions?.map((item: any) => (item?.id)) };
        // const devicesIds = await getDevicesIds();
        // await createMessageByDeviceIdsArray({
        //   ...message,
        //   devicesIds: devicesIds,
        // });
        const res: any = await sendMessageToAll(data);
        if (res.data.data.status == "success") {
          console.log('response===|', res)
          await createActivityReq('Impromptu Message Send Successfully')
          setSelectedOptions([])
          setText('')
          setFile(null)
          fileInputRef.current.value = '';
          clearBlobUrl()
          setSelectedGroup(undefined);
          toast.success("Message sent successfully");
        }
        setSelectedOptions([])
        clearBlobUrl()
      } else {
        toast.error("Please fill all fields.");
      }
      setLoading(false);
      setSelectedOptions([])
    } catch (e) {
      setLoading(false);
      HandleUIError(e);
    }
  }

  const onSelect = (selectedList: any, selectedItem: any) => {
    setSelectedOptions(selectedList);
  };

  const onRemove = (selectedList: any, removedItem: any) => {
    setSelectedOptions(selectedList);
  };

  function getEditorWidth() {
    let width = windowSize.innerWidth;
    if (windowSize.innerWidth > 768) {
      width = (windowSize.innerWidth - 340) / 2;
    } else if (windowSize.innerWidth > 576) {
      width = windowSize.innerWidth / 2;
    } else if (windowSize.innerWidth < 576) {
      width = windowSize.innerWidth;
    }
    return width;
  }

  // @ts-ignore
  return (
    <div className="new-staff-container w-100 mt-2">
      <div className="new-template-header d-flex justify-content-between align-items-center">
        <p className="text-light fs-5">Impromptu Message</p>
      </div>
      <div className="px-3 pt-2 d-flex align-items-center justify-content-between" style={{gap: 15}}>
        {/*<CFormSelect className="template-select" onChange={onChangeTemplate}>
          <option value="">Please Select Recipient Group</option>
          {groups?.map((template, index) => {
            return (
              <option value={template.id} key={index}>
                {template.name}
              </option>
            );
          })}
        </CFormSelect>*/}
        <div className="mb-3">
          <Multiselect
              options={options}
              selectedValues={selectedOptions?.length > 0 && selectedOptions}
              onSelect={onSelect}
              onRemove={onRemove}
              displayValue="value"
              showCheckbox={true}
              className="custom-multiselect"
              style={{
                color: '#fff'
              }}
          />
        </div>
        <div className="d-flex justify-content-center py-3 mb-2">
          <GroupButtons>
            <ButtonForGroup
              label="Text"
              checked={messageType === "message"}
              onClick={onPressText}
            />
            <ButtonForGroup
              label="Audio"
              checked={messageType === "voice"}
              onClick={onPressAudio}
            />
            <ButtonForGroup
                label="Image"
                checked={messageType === "image"}
                onClick={onPressImage}
            />
          </GroupButtons>
        </div>
      </div>

      {messageType === "message" && (
        <>
          <div className="search-phone d-flex flex-column">
            <div className="px-4 mb-4">
              <textarea
                  rows={5}
                  className="form-control shadow-none"
                  style={{
                    backgroundColor: '#24242C',
                    border: '2px solid #44454a',
                    color: '#51fffe',
                  }}
                  value={text}
                  maxLength={300}
                  onChange={(e) => setText(e.target.value)}
              />
              <div className="p-1 rounded-2 px-2" style={{border: '2px solid #44454a'}}>
                <p className="py-1 text-end">{`${text?.length}/300`}</p>
              </div>
            </div>
            {/*<div
              className="text-editor-container-impromptu"
              style={{
                maxWidth: getEditorWidth(),
              }}
            >

              <TextEditor
                className=""
                defaultValue={text}
                onChangeText={onChangeText}
                placeHolder="Type message here. Message will be sent by email, text, and GuardianCall devices."
              />
            </div>*/}
            {checkPermission('Send Group Message') && (
                <div className="align-self-end px-3 pb-3">
                  <Button
                      onClick={() => triggerEndpointService(text)}
                      disabled={loading}
                      className="px-4"
                  >
                    {loading ? <Spinner /> : "Send"}
                  </Button>
                </div>
            )}
          </div>
        </>
      )}
      {messageType === "voice" &&(
          <>
            {/*<AudioRecorder deviceId={devicesIds} />*/}
            <div className="text-center d-flex flex-column align-content-center" style={{minHeight: 250}}>
              <p>{status}</p>

              {status === 'idle' && (
                  <Button className="mt-2 w-50 mx-auto" onClick={startRecording}>
                    START RECORDING
                    <CIcon className="ms-1" size="xl" icon={cilMic} />
                  </Button>
              )}
              <div className="d-flex">
                {status === "recording" && (
                    <div className="d-flex align-items-center ms-4">
                      <div className="recording-status p-0 m-0 me-1" />
                      <ChronometerAudioMessage className="audio-time-text m-0 p-0" />
                    </div>
                )}
                {status === "recording" && (
                    <Button
                        className="stop-button-container m-0 p-0 me-2"
                        onClick={stopRecording}
                    >
                      <div className="icon-stop-button" />
                    </Button>
                )}
              </div>
              <div className="d-flex">
                {mediaBlobUrl && (
                    <AudioPlayer
                        src={mediaBlobUrl}
                        className="audio-player-container"
                    />
                )}
                {mediaBlobUrl && (
                    <IconButton
                        icon={cilTrash}
                        size="xxl"
                        onClick={() => clearBlobUrl()}
                    />
                )}
              </div>
            </div>

            {checkPermission('Send Group Message') && (
                <div className="d-flex justify-content-end px-3 pb-3">
                  <Button
                      onClick={() => triggerEndpointService(mediaBlob)}
                      disabled={loading}
                      className="px-4"
                  >
                    {loading ? <Spinner /> : "Send"}
                  </Button>
                </div>
            )}
          </>
      )}

      {messageType === "image" &&(
          <>
            <div className="search-phone d-flex flex-column">
              <div className="text-center" style={{minHeight: 250}}>
                <input
                    type="file"
                    className="form-control w-50 mx-auto mt-4"
                    defaultValue={""}
                    onChange={handleFileChange}
                    accept={"image/*"}
                    ref={fileInputRef}
                />
              </div>
              {checkPermission('Send Group Message') && (
                  <div className="align-self-end px-3 pb-3">
                    <Button
                        onClick={() => triggerEndpointService(file)}
                        disabled={loading}
                        className="px-4"
                    >
                      {loading ? <Spinner /> : "Send"}
                    </Button>
                  </div>
              )}
            </div>
          </>
      )}

    </div>
  );
};

export default ImpromptuMessage;
