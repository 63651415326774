import * as React from "react";
import "../../views/Alerts.scss";

//components
import { MenuHeader } from "../Atomics";
import { AlertSettingsItem } from ".";
import CIcon from "@coreui/icons-react";
import { NewAlertType } from ".";

//services
import { getAlerts, getAlertCategory } from "../../services/alertServices";
import { AlertTypeResponse } from "../../services/types";
import HandleUIError from "../../utils/HandleUIError";
import { CSpinner } from "@coreui/react";

//icons
import { cilPlus } from "@coreui/icons";
import AlertCategoryModal from "./AlertCategoryModal";
import AlertCategoryItem from "./AlertCategoryItem";
import usePermission from "../../hooks/usePermission";

const AlertCategories: React.FC = () => {
  const { checkPermission } = usePermission()
  const [visibleNewAlertType, setVisibleNewAlertType] =
    React.useState<boolean>(false);
  const [alerts, setAlerts] = React.useState<AlertTypeResponse[]>();
  const [openedModalFrom, setOpenedModalFrom] = React.useState<number>(1);
  const [selectedAlertType, setSelectedAlertType] = React.useState<string>("");
  const [selectedAlertTypeName, setSelectedAlertTypeName] = React.useState<string>("");
  const [alertCategoryType, setAlertCategoryType] = React.useState<any>([]);
  // const [numberOfPages, setNumberOfPages] = React.useState(0);

  React.useEffect(function () {
    refreshAlerts();
  }, []);

  function showModal(id: typeof selectedAlertType, name: string, type: any, openedFrom: number): void {
    openVisibleNewAlertType();
    setOpenedModalFrom(openedFrom);
    setSelectedAlertType(id);
    setSelectedAlertTypeName(name)
    setAlertCategoryType(type)
  }

  async function refreshAlerts() {
    try {
      const response = await getAlertCategory();
      setAlerts(response.data);
    } catch (e) {
      HandleUIError(e);
    }
  }

  function showEditModal(id: string, name: string, type: any, openedFrom = 2) {
    showModal(id, name, type, openedFrom);
  }

  function openVisibleNewAlertType() {
    setVisibleNewAlertType(true);
    setOpenedModalFrom(1);
  }

  function closeVisibleNewAlertType() {
    setVisibleNewAlertType(false);
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <p className="text-color fs-4 mt-3 mb-2">
          Setup the sequence each type of alert will initiate.
        </p>
        {checkPermission("Create And Edit Category") && (
            <div
                className="add-icon-container mg-lt add-icon-staff"
                onClick={openVisibleNewAlertType}
            >
              <CIcon icon={cilPlus} size="xl" className="add-icon" />
            </div>
        )}
      </div>
      <MenuHeader className="d-flex justify-content-between">
        <p className="text-color py-3 px-3">Name</p>
        <p className="text-color py-3 px-3 me-5">Default</p>
      </MenuHeader>
      {alerts ? (
        alerts.map((alert) => {
          return (
            <AlertCategoryItem
              alertType={alert}
              key={alert.name}
              //there cannot be two alerts with the same name
              refreshAlerts={refreshAlerts}
              showModal={showEditModal}
              alertId={alert.id.toString()}
            />
          );
        })
      ) : (
        <div className="d-flex justify-content-center mt-4">
          <CSpinner variant="grow" />
        </div>
      )}
      {visibleNewAlertType && (
          <AlertCategoryModal
              refreshAlerts={refreshAlerts}
              visible={visibleNewAlertType}
              closeModal={closeVisibleNewAlertType}
              openedFrom={openedModalFrom}
              alertId={selectedAlertType}
              alertName={selectedAlertTypeName}
              alertCategories={alertCategoryType?.map((val: any) => ({ id: val.id, value: val?.name }))}
          />
      )}
    </div>
  );
};

export default AlertCategories;
