import * as React from "react";
import { Toaster, ToastOptions } from "react-hot-toast";
import "./Toaster.scss";

const ToasterConfig: React.FC = () => {
  return (
    <Toaster
      position="top-right"
      containerStyle={{ top: 65, right: 25 }}
      toastOptions={ToasterOptions}
    />
  );
};

export default ToasterConfig;

const ToasterOptions: ToastOptions = {
  className: "toaster-container",
};
