import * as React from "react";
import "../../views/Alerts.scss";

//components
import { Modal, IconButton, Input, Button, Spinner } from "../Atomics";
import { cilX } from "@coreui/icons";
import HandleUIError from "../../utils/HandleUIError";

//services
import { CFormCheck } from "@coreui/react";
import {
  createNewAlertType,
  getAlertCategory,
  getAlerts,
  updateAlertType,
} from "../../services/alertServices";
import { useEffect, useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import s3 from "../../utils/aws-config";
import toast from "react-hot-toast";
import {createActivityReq} from "../../services/activityLogService";

interface Props {
  visible: boolean;
  closeModal: () => void;
  refreshAlerts: () => Promise<void>;
  alertId: string;
  alertName: string;
  alertColor: string;
  alertCategory: any;
  alertIcon: any;
  openedFrom: number; // 1 likes edit, 2 likes create
  alerts: any;
}

// @ts-ignore
const NewAlertType: React.FC<Props> = ({
  visible,
  closeModal,
  refreshAlerts,
  openedFrom,
  alertId,
  alertName,
  alertColor,
  alertCategory,
  alertIcon,
    alerts,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [name, setName] = React.useState(alertName || "");
  const [color, setColor] = useState(alertColor || "");
  const [icon, setIcon] = useState(null);
  const [isDefault, setIsDefault] = React.useState(false);
  const [isHomeAler, setIsHomeAler] = React.useState(false);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [errorImage, setErrorImage] = React.useState(false);
  const maxWidth = 200;
  const maxHeight = 200;
  const maxSizeInBytes = 1024 * 1024; // 1MB

  const onSelect = (selectedList: any, selectedItem: any) => {
    setSelectedOptions(selectedList);
  };

  const onRemove = (selectedList: any, removedItem: any) => {
    setSelectedOptions(selectedList);
  };

  function onChangeIsDefault() {
    setIsDefault(!isDefault);
  }

  function onChangeIsDefaultHomeAlert() {
    setIsHomeAler(!isHomeAler);
  }

  function onChangeName(e: any) {
    setName(e.target.value);
  }

  const handleFileUpload = async (file: any) => {
    return new Promise((resolve, reject) => {
      const params = {
        Bucket: "callguardian",
        Key: file.name,
        Body: file,
        ContentType: file.type,
        ContentDisposition: "inline",
      };

      s3.upload(params, (err: any, data: any) => {
        if (err) {
          console.error("Error uploading file:", err);
          reject(err);
        } else {
          console.log("File uploaded successfully:", data.Location);
          resolve(data.Location);
        }
      });
    });
  };

  const handleFileChange = (event: any) => {
    const files = event.target.files;

    if (files) {
      // Filter images with dimensions less than 200x200 and size less than 1MB
      const filteredImages: any = Array.from(files).filter((file: any) => {
        const img = new Image();
        img.src = URL.createObjectURL(file);

        return (
          img.width <= maxWidth &&
          img.height <= maxHeight &&
          file.size <= maxSizeInBytes
        );
      });

      if (filteredImages.length < 1) {
        setErrorImage(true);
      } else {
        setErrorImage(false);
      }

      setIcon(filteredImages[0]);
    }
  };

  async function onPressSend() {
    const isMoreThanThree = alerts?.filter((item: any) => item?.isHomeAlert)?.length -1
    console.log('alert', isHomeAler)
    if (isHomeAler) {
      if (isMoreThanThree < 2) {
        if (name !== "") {
          setLoading(true);
          try {
            // @ts-ignore
            const category = // @ts-ignore
                selectedOptions?.length > 0 ? selectedOptions[0]?.id : "";

            const iconImage = icon ? await handleFileUpload(icon) : alertIcon;

            if (openedFrom === 2) {
              await updateAlertType(name, color, iconImage, category, alertId);
              await createActivityReq('Alert Setting Updated Successfully')
            } else {
              await createNewAlertType(name, color, iconImage, category, isDefault, isHomeAler);
              await createActivityReq('Alert Setting Added Successfully')
            }
            refreshAlerts();
            setLoading(false);
            closeModal();
            setName("");
          } catch (e) {
            HandleUIError(e);
            closeModal();
            setName("");
            setLoading(false);
          }
        }
      } else {
        toast.error('Please Checked Less Than 4 Home Alerts');
      }
    } else {
      if (name !== "") {
        setLoading(true);
        try {
          // @ts-ignore
          const category = // @ts-ignore
              selectedOptions?.length > 0 ? selectedOptions[0]?.id : "";

          const iconImage = icon ? await handleFileUpload(icon) : alertIcon;

          if (openedFrom === 2) {
            await updateAlertType(name, color, iconImage, category, alertId);
          } else {
            await createNewAlertType(name, color, iconImage, category, isDefault, isHomeAler);
          }
          refreshAlerts();
          setLoading(false);
          closeModal();
          setName("");
        } catch (e) {
          HandleUIError(e);
          closeModal();
          setName("");
          setLoading(false);
        }
      }
    }
  }

  useEffect(() => {
    fetchAlerts();
  }, []);

  async function fetchAlerts() {
    try {
      const response = await getAlertCategory();
      const options = response?.data?.map((val) => {
        return {
          id: val?.id,
          value: val?.name,
        };
      });
      console.log("options", options);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      // @ts-ignore
      const newOption: any =
        openedFrom === 2
          ? options?.length > 0 &&
            options?.filter((data: any) => data.id == alertCategory?.id)
          : [];
      setSelectedOptions(newOption);

      // @ts-ignore
      setOptions(options);
    } catch (e) {
      HandleUIError(e);
    }
  }

  console.log("options", selectedOptions);

  return (
    <>
      <Modal visible={visible} onPressOut={closeModal}>
        <div className="new-staff-container new-alert-type-container">
          <div className="new-template-header d-flex justify-content-between align-items-center">
            <p className="text-light fs-5">
              {openedFrom === 2 ? "Edit Alert Type" : "New Alert Type"}
            </p>
            <IconButton icon={cilX} onClick={closeModal} />
          </div>
          <div className="d-flex align-items-center ms-3 mt-3">
            <p className="fs-5 m-0 p-0 me-2">Name:</p>
            {openedFrom === 2 ? (
              <Input defaultValue={alertName} onChange={onChangeName} />
            ) : (
              <Input onChange={onChangeName} />
            )}
          </div>

          <div className="d-flex align-items-center ms-3 mt-3">
            <p className="fs-5 m-0 p-0 me-2">Color: </p>
            {openedFrom === 2 ? (
              <input
                type="color"
                defaultValue={alertColor}
                onChange={(e) => setColor(e.target.value)}
              />
            ) : (
              <input
                type="color"
                value={color}
                onChange={(e) => setColor(e.target.value)}
              />
            )}
          </div>

          <div className="d-flex align-items-center ms-3 mt-3">
            <p className="fs-5 m-0 p-0 me-2">Icon:</p>
            {openedFrom === 2 ? (
              <input
                type="file"
                className="form-control w-50"
                onChange={handleFileChange}
              />
            ) : (
              <input
                type="file"
                className="form-control w-50"
                onChange={handleFileChange}
                accept="image/*"
              />
            )}
          </div>

          <div className="px-3">

            <>
              {icon ? (
                  <div className="mt-3">
                    <h6>Selected Image</h6>
                    <img
                        src={URL.createObjectURL(icon)}
                        alt={`Selected 1`}
                        width={30}
                        height={30}
                    />
                  </div>
              ) : (
                  <>
                    {(alertIcon && openedFrom === 2) && (
                        <div className="mt-3">
                          <h6>Icon</h6>
                          <img
                              src={alertIcon}
                              alt={`Selected 1`}
                              width={30}
                              height={30}
                          />
                        </div>
                    )}
                  </>
              )}
            </>
          </div>



          <div className="px-3">
            {errorImage && (
              <div>
                <p className="text-danger">
                  Images with dimensions less than 200x200 and size less than
                  1MB
                </p>
              </div>
            )}
          </div>

          {openedFrom !== 2 && (
            <div className="d-flex align-items-center ms-3 mt-3">
              <p className="fs-5 m-0 p-0 me-2">Set this alert as default?</p>
              <CFormCheck checked={isDefault} onChange={onChangeIsDefault} />
            </div>
          )}

          {true && (
            <>
              <div
                style={{
                  width: 300,
                }}
                className="ms-3 mt-3"
              >
                <p className="fs-5 mb-1 p-0 me-2">Category:</p>
                <Multiselect
                  singleSelect={true}
                  options={options}
                  selectedValues={selectedOptions}
                  onSelect={onSelect}
                  onRemove={onRemove}
                  displayValue="value"
                />
              </div>
            </>
          )}

          {openedFrom !== 2 && (
              <div className="d-flex align-items-center ms-3 mt-3">
                <p className="fs-5 m-0 p-0 me-2">Is Home Alert</p>
                <CFormCheck checked={isHomeAler} onChange={onChangeIsDefaultHomeAlert} />
              </div>
          )}

          <div className="d-flex justify-content-end px-3 pb-3">
            <Button onClick={onPressSend} disabled={loading} className="px-4">
              {loading ? <Spinner /> : "Save"}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NewAlertType;
