import * as React from "react";
import "./Atomics.scss";

interface Props {
  visible: boolean;
  className?: string;
  onPressOut?: () => void;
  backgroundClassName?: string;
}

const Modal: React.FC<Props> = ({
  children,
  visible,
  className,
  backgroundClassName,
  onPressOut,
}) => {
  return (
    <>
      {visible ? (
        <div>
          <div
            className={`custom-modal-background ${backgroundClassName}`}
            onClick={onPressOut}
          />
          <div className={`custom-modal-children ${className}`}>{children}</div>
        </div>
      ) : null}
    </>
  );
};

export default Modal;
