import * as React from "react";
import "./NewStaff.scss";

//components
import { Modal, IconButton, Input, Button, Spinner } from "../Atomics";
import { cilX } from "@coreui/icons";
import { GroupResponse } from "../../services/types";
import { addGroup, editGroupById } from "../../services/groupService";
import toast from "react-hot-toast";
import HandleUIError from "../../utils/HandleUIError";

interface Props {
  visible: boolean;
  closeModal: () => void;
  editMode?: boolean;
  groupDetails?: GroupResponse;
  refreshGroups: () => void;
  refreshGroupInfo: () => void;
}

const NewGroup: React.FC<Props> = ({
  visible,
  closeModal,
  editMode,
  groupDetails,
  refreshGroups,
  refreshGroupInfo
}) => {
  const [groupName, setGroupName] = React.useState<any>(groupDetails?.name)
  const [groupDescription, setGroupDescription] = React.useState<any>(groupDetails?.description)
  const [loadingButton, setLoadingButton] = React.useState<boolean>(false)

  function defaultValueName(): string {
    if (editMode && groupDetails) {
      return groupDetails.name;
    }
    return "";
  }

  function defaultValueDescription(): string {
    if (editMode && groupDetails) {
      return groupDetails.description;
    }
    return "";
  }

  function handleInputName(e: any) {
    setGroupName(e.target.value)
  }

  function handleInputDescription(e: any) {
    setGroupDescription(e.target.value)
  }

  async function handleSave() {
    setLoadingButton(true);
    try {
      await addGroup(groupName, groupDescription);
      setLoadingButton(false);
      refreshGroups();
      toast.success(`Group "${groupName}" created successfully`);
    } catch (e) {
      setLoadingButton(false);
      HandleUIError(e);
    }
    closeModal();
  }

  async function handleUpdate(groupId: number) {
      setLoadingButton(true);
      try {
        await editGroupById(groupId, groupName, groupDescription)
        setLoadingButton(false);
        refreshGroups();
        refreshGroupInfo();
      } catch (e) {
        setLoadingButton(false);
        HandleUIError(e);
      }
      closeModal();
  }
  

  return (
    <Modal visible={visible} onPressOut={closeModal}>
      <div className="new-group-container">
        <div className="new-staff-header">
          <p className="text-light py-2 ps-4 fs-5">
            {editMode ? "Edit details" : "New Group"}
          </p>
          <IconButton icon={cilX} className="me-3" onClick={closeModal} />
        </div>
        <div className="d-flex align-items-end mt-2">
          <p className="text-light fs-5 ms-5 me-2 pt-2">Name:</p>
          <Input
            className="mt-3 mx-3 search-phone-input ms-4"
            defaultValue={defaultValueName()}
            onChange={(e) => handleInputName(e)}
            autoFocus
          />
        </div>
        <div className="d-flex align-items-star mt-2">
          <p className="text-light fs-5 ms-3 pt-3">Description:</p>
          <Input
            className="mt-3 mx-3 search-phone-input"
            onChange={(e) => handleInputDescription(e)}
            defaultValue={defaultValueDescription()}
          />
        </div>
        <div className="d-flex justify-content-end pe-3 py-3">
          {groupDetails !== undefined && editMode ? (
            <>
            {loadingButton ? (
              <Button disabled><Spinner/></Button>
              ):(
              <Button onClick={() => handleUpdate(groupDetails.id)}>Save</Button>
              )}
            </>
            ):(
            <>
            {loadingButton ? (
              <Button disabled><Spinner /></Button>
            ):(
              <Button onClick={handleSave}>Save</Button>
            )}
            </>
            )}
        </div>
      </div>
    </Modal>
  );
};

export default NewGroup;
