import * as React from "react";

//components
import { CFormSelect } from "@coreui/react";
import {
  AlertTypeAction,
  TemplateMessageResponse,
  GroupResponse,
} from "../../services/types";
import { IconButton, Modal, Spinner } from "../Atomics";
import { cilTrash } from "@coreui/icons";
import toast from "react-hot-toast";
import { GroupButtons, ButtonForGroup } from "../Atomics/GroupButtons";

//services
import {
  editAlertActionById,
  deleteAlertAction,
} from "../../services/alertServices";
import HandleUIError from "../../utils/HandleUIError";
import confirmationDialog from "../toast-dialogs/ConfirmationDialog";
import {searchParents} from "../../services/rolesService";
import {useEffect, useState} from "react";

interface Props {
  action: AlertTypeAction;
  messageTemplateText: TemplateMessageResponse[];
  messageTemplateAudio: TemplateMessageResponse[];
  messageTemplateHtml: TemplateMessageResponse[];
  groups: GroupResponse[];
  index: number;
  alertId: number;
  refreshActions: () => void;
}

const AlertTypeActionItem: React.FC<Props> = ({
  action,
  index,
  messageTemplateAudio,
  messageTemplateText,
  messageTemplateHtml,
  alertId,
  refreshActions,
  groups,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [roles, setRoles] = useState<any>([])
    const [tab, setTab] = useState<any>(0)

  React.useEffect(() => {
    fetch()
  }, []);

  function onPressDelete() {
    confirmationDialog({
      title: "Are you sure you want to delete this action?",
      confirmAction: throwDeleteActionEndpoint,
    });
  }

  async function throwDeleteActionEndpoint() {
    setLoading(true);
    try {
      await deleteAlertAction(action.id);
      toast.success("Action deleted successfully");
      refreshActions();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      HandleUIError(e);
    }
  }

  async function onSetSendStaff() {
    setLoading(true);
    try {
      await editAlertActionById(alertId, action.id, {
        sendStaff: "all",
        sendToStaffGroupId: null,
        sendToParents: false,
      });
      await refreshActions();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      HandleUIError(e);
    }
  }

  async function onSetSendParents() {
    setLoading(true);
    try {
      await editAlertActionById(alertId, action.id, {
        sendToParents: true,
        sendStaff: "none",
        sendToStaffGroupId: null,
      });
      await refreshActions();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      HandleUIError(e);
    }
  }

  async function onSetSendTo(e: any) {
    setLoading(true);
    try {
      await editAlertActionById(alertId, action.id, {
        sendStaff: e.target.value,
      });
      await refreshActions();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      HandleUIError(e);
    }
  }

  async function onSendRole(e: any) {
    setLoading(true);
    try {
      await editAlertActionById(alertId, action.id, {
        role_id: e.target.value == 'null' ? null : e.target.value,
      });
      await refreshActions();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      HandleUIError(e);
    }
  }

  async function onSetGroupToSend(e: any) {
    let value = e.target.value;
    if (value === "none") {
      value = null;
    }
    setLoading(true);
    try {
      await editAlertActionById(alertId, action.id, {
        sendToStaffGroupId: e.target.value,
      });
      await refreshActions();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      HandleUIError(e);
    }
  }

  async function onSetOnlyFacility(e: any) {
    let value = e.target.value;
    setLoading(true);
    try {
      await editAlertActionById(alertId, action.id, {
        onlyFacility: value === "yes",
      });
      await refreshActions();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      HandleUIError(e);
    }
  }

  async function onChangeTextTemplate(e: any) {
    setLoading(true);
    try {
      let value = e.target.value;
      if (value === "dont_send") {
        value = null;
      }
      await editAlertActionById(alertId, action.id, {
        messageTemplateTextId: value,
        message_template_text_id: value,
      });
      await refreshActions();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      HandleUIError(e);
    }
  }

  async function onChangeSmsTemplate(e: any) {
    setLoading(true);
    try {
      let value = e.target.value;
      if (value === "dont_send") {
        value = null;
      }
      await editAlertActionById(alertId, action.id, {
        messageTemplateSmsId: value,
        message_template_sms_id: value,
      });
      await refreshActions();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      HandleUIError(e);
    }
  }
  async function onChangeAudioTemplate(e: any) {
    setLoading(true);
    try {
      let value = e.target.value;
      if (value === "dont_send") {
        value = null;
      }
      await editAlertActionById(alertId, action.id, {
        messageTemplateAudioId: value,
        message_template_audio_id: value,
      });
      await refreshActions();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      HandleUIError(e);
    }
  }
  async function onChangeEmailTemplate(e: any) {
    setLoading(true);
    try {
      let value = e.target.value;
      if (value === "dont_send") {
        value = null;
      }
      await editAlertActionById(alertId, action.id, {
        messageTemplateMailId: value,
        message_template_mail_id: value,
      });
      await refreshActions();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      HandleUIError(e);
    }
  }

  const fetch = async () => {
    const data = await searchParents(0, 1, '')
    const data2 = data?.data?.reverse()
    setRoles(data2)
    // setTab(`${data2[0]?.id}`)
  }



  return (
    <div
      key={index}
      className="d-flex flex-wrap mt-1 alert-type-action-item-container align-items-center justify-content-between"
    >
      <div className="col-md-12 d-none mb-3">
        <GroupButtons className="m-0 me-2">
          <ButtonForGroup
              label="Staff"
              checked={action.sendStaff !== "none"}
              onClick={onSetSendStaff}
          />
          <ButtonForGroup
              label="Parents"
              checked={action.sendToParents}
              onClick={onSetSendParents}
          />
          {roles?.map((role: any) => (
              <ButtonForGroup
                  label={`${role?.role_name}`}
                  checked={tab == `${role?.id}`}
                  onClick={() => {
                    setTab(`${role?.id}`)
                    // changeToStaff()
                  }}
              />
          ))}
        </GroupButtons>
      </div>
      <div className="col-md-11 ">
        {action.sendToParents && (
            <div className="d-flex d-none">
              <div className="m-0 me-2 w-100">
                <p className="text-light  me-2">Email:</p>
                <CFormSelect
                    onChange={onChangeEmailTemplate}
                    className="template-select w-100"
                    value={
                      action.messageTemplateMailId
                          ? action.messageTemplateMailId
                          : "don_t_send"
                    }
                >
                  <option value="dont_send">(Don't send)</option>
                  {messageTemplateHtml?.map((template) => {
                    return <option value={template.id}>{template.name}</option>;
                  })}
                </CFormSelect>
              </div>
              <div className=" m-0 me-2 w-100">
                <p className="text-light  me-2">Sms:</p>
                <CFormSelect
                    onChange={onChangeSmsTemplate}
                    className="template-select w-100"
                    value={
                      action.message_template_sms_id
                          ? action.message_template_sms_id
                          : "don_t_send"
                    }
                >
                  <option value="dont_send">(Don't send)</option>
                  {messageTemplateText?.map((template) => {
                    return <option value={template.id}>{template.name}</option>;
                  })}
                </CFormSelect>
              </div>
              <div className=" m-0 me-2 w-100">
                <p className="text-light  me-2">Phone Call:</p>
                <CFormSelect
                    onChange={onChangeAudioTemplate}
                    className="template-select w-100"
                    value={
                      action.message_template_audio_id
                          ? action.message_template_audio_id
                          : "don_t_send"
                    }
                >
                  <option value="dont_send">(Don't send)</option>
                  {messageTemplateAudio?.map((template) => {
                    return <option value={template.id}>{template.name}</option>;
                  })}
                </CFormSelect>
              </div>
            </div>
        )}
        {action.sendStaff !== "none" && <></>}
        <div className="w-100">
          <div className="d-flex">
            <div className=" m-0 me-2 w-100">
              <p className="text-light  me-2">Select Role:</p>
              <CFormSelect
                  onChange={onSendRole}
                  className="template-select w-100"
                  value={`${action.role_id}`}
              >
                <option value="null">All</option>
                {roles?.map((role: any) => (
                    <option value={role?.id}>{role?.role_name}</option>
                ))}
              </CFormSelect>
            </div>
          </div>
          <div className="d-flex">
            <div className=" m-0 me-2 w-100">
              <p className="text-light  me-2">Send to:</p>
              <CFormSelect
                  onChange={onSetSendTo}
                  className="template-select w-100"
                  value={action.sendStaff}
              >
                <option value={"all"}>All</option>
                <option value={"group"}>Group</option>
              </CFormSelect>
            </div>
            {action.sendStaff === "group" && (
                <div className=" m-0 me-2 w-100">
                  <p className="text-light  me-2">Group to send:</p>
                  <CFormSelect
                      onChange={onSetGroupToSend}
                      value={
                        action.sendToStaffGroupId
                            ? action.sendToStaffGroupId
                            : "none"
                      }
                      className="template-select w-100"
                  >
                    <option value="none">(Select Group)</option>
                    {groups.map((group) => (
                        <option value={group.id}>{group.name}</option>
                    ))}
                  </CFormSelect>
                </div>
            )}
            <div className=" m-0 me-2 w-100">
              <p className="text-light  me-2">Send only to facility:</p>
              <CFormSelect
                  value={action.onlyFacility ? "yes" : "no"}
                  onChange={onSetOnlyFacility}
                  className="template-select w-100"
              >
                <option value="no">No</option>
                <option value="yes">Yes</option>
              </CFormSelect>
            </div>
          </div>
          <div className="d-flex">
            <div className=" m-0 me-2 w-100">
              <p className="text-light  me-2">Email:</p>
              <CFormSelect
                  onChange={onChangeEmailTemplate}
                  className="template-select w-100"
                  value={
                    action.messageTemplateMailId
                        ? action.messageTemplateMailId
                        : "don_t_send"
                  }
              >
                <option value="dont_send">(Don't send)</option>
                {messageTemplateHtml?.map((template) => {
                  return <option value={template.id}>{template.name}</option>;
                })}
              </CFormSelect>
            </div>
            <div className=" m-0 me-2 w-100">
              <p className="text-light  me-2">Sms:</p>
              <CFormSelect
                  onChange={onChangeSmsTemplate}
                  className="template-select w-100"
                  value={
                    action.messageTemplateSmsId
                        ? action.messageTemplateSmsId
                        : "don_t_send"
                  }
              >
                <option value="dont_send">(Don't send)</option>
                {messageTemplateText?.map((template) => {
                  return <option value={template.id}>{template.name}</option>;
                })}
              </CFormSelect>
            </div>
            <div className=" m-0 me-2 w-100">
              <p className="text-light  me-2">Phone Call:</p>
              <CFormSelect
                  onChange={onChangeAudioTemplate}
                  className="template-select w-100"
                  value={
                    action.messageTemplateAudioId
                        ? action.messageTemplateAudioId
                        : "don_t_send"
                  }
              >
                <option value="dont_send">(Don't send)</option>
                {messageTemplateAudio?.map((template) => {
                  return <option value={template.id}>{template.name}</option>;
                })}
              </CFormSelect>
            </div>
            <div className=" m-0 me-2 w-100">
              <p className="text-light  me-2">Device Text:</p>
              <CFormSelect
                  onChange={onChangeTextTemplate}
                  className="template-select w-100"
                  value={
                    action.messageTemplateTextId
                        ? action.messageTemplateTextId
                        : "don_t_send"
                  }
              >
                <option value="dont_send">(Don't send)</option>
                {messageTemplateText?.map((template) => {
                  return <option value={template.id}>{template.name}</option>;
                })}
              </CFormSelect>
            </div>
            <div className=" m-0 me-2 w-100">
              <p className="text-light  me-2">Device Audio:</p>
              <CFormSelect
                  onChange={onChangeAudioTemplate}
                  className="template-select w-100"
                  value={
                    action.messageTemplateAudioId
                        ? action.messageTemplateAudioId
                        : "don_t_send"
                  }
              >
                <option value="dont_send">(Don't send)</option>
                {messageTemplateAudio?.map((template) => {
                  return <option value={template.id}>{template.name}</option>;
                })}
              </CFormSelect>
            </div>
          </div>
        </div>
      </div>
      <div className="col-auto">
        <IconButton
            icon={cilTrash}
            className="ms-4 mt-4 delete-action-button"
            onClick={onPressDelete}
        />
      </div>


      <Modal visible={loading}>
        <Spinner className="text-light" />
      </Modal>
    </div>
  );
};

export default AlertTypeActionItem;
