import * as React from "react";
import "../staff/NewStaff.scss";
import "./NewUser.scss"
import HandleUIError from "../../utils/HandleUIError";
import toast from "react-hot-toast";

//components
import {
  Modal,
  Input,
  Button,
  Spinner,
  IconButton,
} from "../Atomics";
import { cilX } from "@coreui/icons";
import { editUserAdmin } from "../../services/usersService";
import {useEffect, useState} from "react";
import {searchParents} from "../../services/rolesService";

interface UserToShow {
    id: number;
    phone: string;
    firstName: string;
    lastName: string;
    email: string;
    password: any;
  roleId: any;
}

interface Props {
  visible: boolean;
  closeModal: () => void;
  refreshUsers: () => void;
  userData: UserToShow
}

const EditUser: React.FC<Props> = ({ visible, closeModal, refreshUsers, userData}) => {
  const [firstName, setFirstName] = React.useState<string>(userData.firstName);
  const [lastName, setLastName] = React.useState<string>(userData.lastName);
  const [email, setEmail] = React.useState<string>(userData.email);
  const [phone, setPhone] = React.useState<string>(userData.phone);
    const [password, setPassword] = React.useState<string>(userData.password);
  const [addUserLoading, setAddUserLoading] = React.useState<boolean>(false);
  const [roles, setRoles] = useState<any>([])
  const [roleId, setRoleID] = useState<any>(userData?.roleId ||'')

  const handleInputFirstName = (e: any) => {
    setFirstName(e.target.value);
  };

  const handleInputLastName = (e: any) => {
    setLastName(e.target.value);
  };

  const handleInputMail = (e: any) => {
    setEmail(e.target.value);
  };
  
  const handleInputPhone = (e: any) => {
    setPhone(e.target.value);
  };
  const handleInputPassword = (e: any) => {
    setPassword(e.target.value);
  };

  async function changeUser(id: number, firstName: string, lastName: string, email: string, phone: string, role_id: any, password: any): Promise<void> {
    setAddUserLoading(true);
      try {
        if( email === "" || email === null ){
          toast.error("Email cannot be empty")
          setAddUserLoading(false);
        }else{
          await editUserAdmin(id, firstName, lastName, email, phone, role_id, password)
          await refreshUsers();
          toast.success("User modified");
          setAddUserLoading(false);
          onCloseModal();
        }
      } catch (e) {
        setAddUserLoading(false);
        HandleUIError(e);
      }
  }

  function onCloseModal() {
    closeModal();
  }
  const fetch = async () => {
    const data = await searchParents(0, 1, '')
    setRoles(data?.data)
  }

  useEffect(() => {
    fetch()
  }, []);

  console.log('userData', userData)

  return (
    <Modal visible={visible} onPressOut={onCloseModal}>
      <div className="new-staff-container">
        <div className="new-staff-header">
          <p className="text-light py-2 ps-4 fs-5">Edit User</p>
          <IconButton icon={cilX} className="me-3" onClick={closeModal} />
        </div>
      
          <div className="users-edit-container">
            <p className="text-light fs-5 ms-3 pt-3">
              First Name
            </p>
            <Input
              value={firstName}
              className="mt-2 mb-3 ms-3 search-phone-input"
              onChange={handleInputFirstName}
              autoFocus
            />  
            <p className="text-light fs-5 ms-3 pt-3">
              Last Name
            </p>
            <Input
              value={lastName}
              className="mt-2 mb-3 ms-3 search-phone-input"
              onChange={handleInputLastName}
            />
            <p className="text-light fs-5 ms-3 pt-3">
              Email
            </p>
            <Input
              value={email}
              className="mt-2 mb-3 ms-3 search-phone-input"
              onChange={handleInputMail}
            />
            <p className="text-light fs-5 ms-3 pt-3">
              Select Role
            </p>
            <div className="px-3">
              <select
                  className="input-custom form-select"
                  value={roleId}
                  defaultValue={userData?.roleId || ''}
                  onChange={(e) => setRoleID(e.target.value)}
              >
                <option value="">Please Select</option>
                {roles?.map((item: any) => (
                    <option value={`${item?.id}`}>{item?.role_name}</option>
                ))}
              </select>
            </div>
            <p className="text-light fs-5 ms-3 pt-3">
              Phone
            </p>
            <Input
              value={phone}
              type="number"
              className="mt-2 mb-3 ms-3 search-phone-input"
              onChange={handleInputPhone}
            />
            <p className="text-light fs-5 ms-3 pt-3">
              Password
            </p>
            <Input
                value={password}
                type="password"
                className="mt-2 mb-3 ms-3 search-phone-input"
                onChange={handleInputPassword}
            />
            <Button
              disabled={addUserLoading}
              className="mt-7 button-add px-4 mb-3"
              onClick={() => changeUser(userData.id, firstName, lastName, email, phone, roleId, password)}
            >
              {!addUserLoading ? "Save changes" : <Spinner />}
            </Button>
          </div>
      </div>
    </Modal>
  );
};

export default EditUser;