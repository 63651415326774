import * as React from "react";
import "./NewStaff.scss";
import { useAppSelector } from "../../redux/hooks";
import HandleUIError from "../../utils/HandleUIError";
import { User } from "../types";
import toast from "react-hot-toast";

//components
import { CSpinner } from "@coreui/react";
import {
  Modal,
  Input,
  UserAvatar,
  Button,
  Spinner,
  IconButton,
} from "../Atomics";
import { cilX } from "@coreui/icons";

//services
import { createNewUser, searchUserToAdd } from "../../services/staffService";
import { addUserToOrganization } from "../../services/staffService";
import { ButtonForGroup, GroupButtons } from "../Atomics/GroupButtons";
import { NewUserStaff } from "../../services/types";
import {createActivityReq} from "../../services/activityLogService";


interface Props {
  visible: boolean;
  closeModal: () => void;
  refreshUsers: () => void;
}

type Options = 'addExistent' | 'create'

const NewStaff: React.FC<Props> = ({ visible, closeModal, refreshUsers }) => {
  const [search, setSearch] = React.useState("");
  const [usersResults, setUsersResults] = React.useState<any>([]);
  const [loadingResults, setLoadingResults] = React.useState(false);
  const [addUserLoading, setAddUserLoading] = React.useState<boolean>(false);
  const [devicesResults, setDevicesResults] = React.useState<boolean>(false);
  const [staffType, setStaffType] = React.useState<Options>("addExistent")
  const organizationId = useAppSelector(
    (state) => state.UserSlice.user.organizationId
  );
  const [firstName, setFirstName] = React.useState<string>("")
  const [lastName, setLastName] = React.useState<string>("")
  const [email, setEmail] = React.useState<string>("")
  const [phone, setPhone] = React.useState<string>("")
  const [password, setPassword] = React.useState<string>("")

  const handleInput = (e: any) => {
    setSearch(e.target.value);
  };

  function handleInputFirstName(e: any){
    setFirstName(e.target.value)
  }
  function handleInputLastName(e: any){
    setLastName(e.target.value)
  }
  function handleInputEmail(e: any){
    setEmail(e.target.value)
  }
  function handleInputPhone(e: any){
    setPhone(e.target.value)
  }
  function handleInputPassword(e: any){
    setPassword(e.target.value)
  }


  async function searchUsers(e: any) {
    if (e.charCode === 13) {
      setLoadingResults(true);
      setDevicesResults(false);
      try {
        const res = await searchUserToAdd(search);
        setUsersResults(res);
        setLoadingResults(false);
        if (res.length < 1) {
          setDevicesResults(true);
        }
      } catch (e) {
        setLoadingResults(false);
        HandleUIError(e);
      }
    }
  }

  async function addUser(userId: number): Promise<void> {
    setAddUserLoading(true);
    if (organizationId) {
      try {
        await addUserToOrganization(userId, organizationId);
        await refreshUsers();
        toast.success("User added to organization");
        setAddUserLoading(false);
        onCloseModal();
      } catch (e) {
        setAddUserLoading(false);
        HandleUIError(e);
      }
    }
  }

  async function createUser(firstName: string, lastName: string, email: string, phone: string, password: string) {
    let infoUser = {
      "firstName": firstName,
      "lastName": lastName,
      "email": email,
      "phone": phone,
      "password": password
    }
    setAddUserLoading(true);
    try {
      await createNewUser(infoUser);
      await refreshUsers();
      toast.success("User created successfully");
      setAddUserLoading(false);
      onCloseModal();
      await createActivityReq('Staff Added Successfully')
    } catch (e) {
      setAddUserLoading(false);
      HandleUIError(e);
    }
  }

  function onCloseModal() {
    setSearch("");
    setUsersResults([]);
    closeModal();
  }

  function onPressAddExistent(){
    setStaffType("addExistent")
  }
  function onPressCreate(){
    setStaffType("create")
  }

  return (
    <Modal visible={visible} onPressOut={onCloseModal}>
      <div className="new-staff-container">
        <div className="new-staff-header">
          <p className="text-light py-2 ps-4 fs-5">New Staff</p>
          <IconButton icon={cilX} className="me-3" onClick={onCloseModal} />
        </div>
        <div className="d-flex justify-content-center py-3 mb-2">
          <GroupButtons>
            <ButtonForGroup
              label="Add Existent"
              checked={staffType === "addExistent"}
              onClick={onPressAddExistent}
            />
            <ButtonForGroup
              label="Create new"
              checked={staffType === "create"}
              onClick={onPressCreate}
            />
          </GroupButtons>
        </div>
        {staffType === "addExistent" && (
        <div>
          <div className="search-phone">
            <p className="text-light fs-5 ms-3 pt-3">
              Search by Phone Number or Email Address
            </p>
            <Input
              className="mt-2 mb-3 ms-3 search-phone-input"
              onChange={handleInput}
              onKeyPress={searchUsers}
              autoFocus
            />
          </div>
          {usersResults.length > 0 && !loadingResults ? (
            <div className="users-container">
              {usersResults.map((user: User) => {
                return (
                  <div className="user-preview-container" key={user.id}>
                    <UserAvatar iconLetter="CI" size={4.5} />
                    <p className="text-color fs-5 mt-2">
                      {user.firstName} {user.lastName}
                    </p>
                    {user.phone === null ? (
                      <p className="text-color fs-5 mt-2">-</p>
                    ) : (
                      <p className="text-color fs-5 mt-2">{user.phone}</p>
                    )}
                    <p className="text-color fs-5 mt-2">{user.email}</p>
                    <Button
                      disabled={addUserLoading}
                      className="mt-7 button-add px-4"
                      onClick={() => addUser(user.id)}
                    >
                      {!addUserLoading ? "Add" : <Spinner />}
                    </Button>
                  </div>
                );
              })}

              {loadingResults && (
                <div className="new-staff-spinner-container">
                  <CSpinner variant="grow" className="new-staff-spinner" />
                </div>
              )}
            </div>
          ) : (
            <div className="users-container">
              {loadingResults && (
                <div className="new-staff-spinner-container">
                  <CSpinner variant="grow" className="new-staff-spinner" />
                </div>
              )}
              {!loadingResults && devicesResults && (
                <div className="user-preview-container">
                  <p className="text-light fs-5 ms-3 pt-3">No results to show</p>
                </div>
              )}
            </div>
          )}
        </div>
        )} 
        {staffType === "create" && (
          <div className="users-container">
            <p className="text-light fs-5 ms-3 pt-3">
              First Name
            </p>
            <Input 
              className="mt-2 mb-3 ms-3 search-phone-input"
              onChange={handleInputFirstName}
            />
            <p className="text-light fs-5 ms-3 pt-3">
              Last Name
            </p>
            <Input 
              className="mt-2 mb-3 ms-3 search-phone-input"
              onChange={handleInputLastName}
            />
            <p className="text-light fs-5 ms-3 pt-3">
              Phone
            </p>
            <Input 
              className="mt-2 mb-3 ms-3 search-phone-input"
              onChange={handleInputPhone}
            />
            <p className="text-light fs-5 ms-3 pt-3">
              Email
            </p>
            <Input 
              className="mt-2 mb-3 ms-3 search-phone-input"
              onChange={handleInputEmail}
            />
            <p className="text-light fs-5 ms-3 pt-3">
              Password
            </p>
            <Input 
              className="mt-2 mb-3 ms-3 search-phone-input"
              onChange={handleInputPassword}
              type="password"
            />
            {!addUserLoading ? (
              <Button
                className="mt-7 button-add px-4 mb-3"
                onClick={() => createUser(firstName, lastName, email, phone, password)}>
                  Create
              </Button>
            ) : (
              <Button
                className="mt-7 button-add px-4 mb-3"
                disabled>
                   <Spinner />
              </Button>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default NewStaff;
