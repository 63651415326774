import ApiError from "../utils/ApiError";
import axiosConfig from "./axiosConfig";

export async function emailVerification(data) {
    try {
        const response = await axiosConfig.post(`/forgot-password`, data);
        return response?.data
    } catch (e) {
        throw new ApiError(e);
    }
}

export async function otpVerification(data) {
    try {
        const response = await axiosConfig.post(`/match-otp`, data);
        return response?.data
    } catch (e) {
        throw new ApiError(e);
    }
}

export async function resetPassword(data) {
    try {
        const response = await axiosConfig.post(`/reset-password`, data);
        return response?.data
    } catch (e) {
        throw new ApiError(e);
    }
}