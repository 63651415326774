import * as React from "react";
import {
  GoogleMap as GoogleMapReact,
  useLoadScript,
  Marker,
  DrawingManager,
  Polygon, Autocomplete,
} from "@react-google-maps/api";
import config from "../../config";

//component
import { Spinner } from "../Atomics";
import HandleUIError from "../../utils/HandleUIError";
import { ZonePointsState, ZoneResponse } from "../../services/types";
import { NumberPerimeterMarker } from ".";
import {useEffect, useRef} from "react";

interface Props {
  center?: Coordinates;
  zoom?: number;
  markers?: Coordinates[] | null;
  drawingMode?: boolean;
  zones?: Array<ZoneResponse>;
  setZonePoints?: (zonePoints: ZonePointsState[]) => void;
  zonesOrder?: { index: number; id: number }[];
  hideNumbers?: boolean;
  onLoad?: (map: any) => void;
  handlePlaceSelect?: (place: any) => void;
  setAutocompletes?: (autocomplete: google.maps.places.Autocomplete) => void;
  pointerMap?: boolean;
  addressName?: string | null;
  handleAddress?: (e: any) => void;
  handleCurrentLocation?: () => void;
}

interface Coordinates {
  lat: number;
  lng: number;
}

const libraries = ["drawing", "places"] as any;

const GoogleMap: React.FC<Props> = ({
  handlePlaceSelect,
  setAutocompletes,
  center,
  zoom,
  markers,
  drawingMode,
  zones,
  setZonePoints,
  zonesOrder,
  hideNumbers,
    onLoad,
    pointerMap,
    addressName,
    handleAddress,
    handleCurrentLocation,
}) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: config.google.googleApiKey,
    libraries,
  });


  const [startDrawing, setStartDrawing] = React.useState<any>(false);
  const [selectedShape, setSelectedShape] = React.useState<any>(null);
  const drawingManagerRef = useRef<any>(null);

  const containerStyle = {
    width: "100%",
    height: "100%",
  };
  const mapOptions: google.maps.MapOptions = {
    disableDefaultUI: true,
    zoomControl: true,
    fullscreenControl: true,
    mapTypeControl: true,
      minZoom: 0,
  };

  const drawingOptions: google.maps.drawing.DrawingManagerOptions = {
    drawingControlOptions: {
      drawingModes: ["polygon"] as google.maps.drawing.OverlayType[],
    },
    drawingControl: startDrawing,
    polygonOptions: {
      fillColor: "#5b99e3",
      strokeColor: "#5b99e3",
      // editable: startDrawing,
      // draggable: startDrawing,
    },
  };

  function onFinishDrawingPolygon(polygon: google.maps.Polygon) {

    // if(polygon){
    //   window.google.maps.event.clearInstanceListeners(polygon);
    // }
    const path = polygon.getPath();
    const points: Coordinates[] = [];
    for (let i = 0; i < path.getLength(); i++) {
      points.push({
        lat: path.getAt(i).lat(),
        lng: path.getAt(i).lng(),
      });
    }
    setZonePoints && setZonePoints(points);
    undoLastShape()
  }

  const undoLastShape = () => {
    console.log('drawingManagerRef.current', drawingManagerRef.current)
    if (drawingManagerRef.current) {
      drawingManagerRef.current?.state.drawingManager?.setDrawingMode(null);
    }
  };

  const removeAllPaths = () => {
    console.log('drawingManagerRef.current', drawingManagerRef.current)
    if (drawingManagerRef.current) {
      drawingManagerRef.current.setMap(null);
      window.google.maps.event.clearInstanceListeners(drawingManagerRef.current);
    }
  };

  const clearSelection = () => {
    if (selectedShape) {
      selectedShape.setEditable(false);
      setSelectedShape(null);
    }
  };

  const setSelection = (shape: any) => {
    clearSelection();
    setSelectedShape(shape);
  };

  const resetPolygon = () => {
    if (selectedShape) {
      selectedShape.setMap(null);
    }
    setSelectedShape(null);
    setStartDrawing(false)
    setZonePoints && setZonePoints([])
  };

  function renderMap() {

    return (
        <>
          {handlePlaceSelect && (
            <div className="position-absolute" style={{top: 60, left: 14, zIndex: 111}}>
              {/*// @ts-ignore*/}
              <Autocomplete onLoad={(auto) => setAutocompletes(auto)} onPlaceChanged={handlePlaceSelect}>
                <input
                    type="text"
                    placeholder="Enter a location"
                    defaultValue={addressName || ''}
                />
              </Autocomplete>
            </div>
          )}
          {(!startDrawing && drawingMode) && (
              <div className="position-absolute" style={{fontSize: 14, top: 60, left: 230, zIndex: 111 }}>
                <button className="btn btn-sm btn-success text-white shaow-sn border rounded-3" onClick={() => setStartDrawing(true)}>Start Drawing</button>
              </div>
          )}
          {selectedShape && (
              <div className="position-absolute" style={{fontSize: 14, top: 60, left: 230, zIndex: 111 }}>
                <button className="btn btn-sm btn-danger text-white shaow-sn border rounded-3" onClick={resetPolygon}>Reset Drawing</button>
              </div>
          )}
          {handleCurrentLocation && (
              <div className="position-absolute" style={{bottom: 118, right: 16, zIndex: 111}}>
                <button
                    className="btn btn-light d-inline-flex align-items-center justify-content-center shadow-sm border px-2"
                    onClick={handleCurrentLocation}
                >
                  <span
                      className="d-inline-block"
                      style={{
                        backgroundImage: `url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-2x.png)`,
                        width: 18,
                        height: 18,
                        backgroundSize: "180px 18px",
                      }}
                  />
                </button>
              </div>
          )}


          <GoogleMapReact
              mapContainerStyle={containerStyle}
              center={center ? center : { lat: 0, lng: 0 }}
              zoom={zoom ? zoom : 4}
              options={mapOptions}
              onLoad={onLoad}
          >
            {drawingMode && (
                <DrawingManager
                    ref={drawingManagerRef}
                    drawingMode={startDrawing && google.maps.drawing.OverlayType.POLYGON}
                    options={drawingOptions}
                    onPolygonComplete={onFinishDrawingPolygon}
                    onOverlayComplete={(e: any) => {
                      if (e.type !== "marker") {
                        const newShape = e.overlay;
                        newShape.type = e.type;
                        google.maps.event.addListener(newShape, 'click', () => {
                          setSelection(newShape);
                        });
                        setSelection(newShape);
                      }
                    }}
                />
            )}
            {zones?.map((zone, index) => (
                <>
                  {zone?.isShow && (
                      <Polygon
                          path={zone?.zonePoints?.map((point) => ({
                            lat: point?.latitude,
                            lng: point?.longitude,
                          }))}
                          options={{ fillColor: "#5b99e3", strokeColor: "#5b99e3" }}
                      />
                  )}

                  {!hideNumbers && zone?.isShow && (
                      <NumberPerimeterMarker
                          number={
                            zonesOrder?.find((zoneOrder) => zoneOrder?.id === zone.id)
                                ?.index
                          }
                          coordinates={{
                            lng: zone?.zonePoints[0]?.longitude,
                            lat: zone?.zonePoints[0]?.latitude,
                          }}
                      />
                  )}
                </>
            ))}

            {console.log('pointerMap', pointerMap)}
            {pointerMap && (
                <Marker
                    position={center ? center : { lat: 0, lng: 0 }}
                />
            )}

            {markers?.map((marker) => {
              return (
                  <Marker
                      position={marker}
                      icon={{
                        url: config.google.icon_marker,
                        scaledSize: new google.maps.Size(70, 70),
                        origin: new google.maps.Point(0, 0),
                        anchor: new google.maps.Point(35, 35),
                      }}
                  />
              );
            })}
          </GoogleMapReact>
        </>
    );
  }

  if (loadError) {
    HandleUIError(loadError);
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? renderMap() : <Spinner />;
};

export default React.memo(GoogleMap);
