import React, {useEffect, useState} from 'react';
import {IconButton, Modal, Spinner} from "./Atomics";
import {cilX} from "@coreui/icons";
import {GoogleMap} from "./google-map";
import usePermission from "../hooks/usePermission";

const AlertModal = ({visible, closeModal, isEdit, loadingClose, loadingApproved, closeAlert, approved, forward}) => {
    const { checkPermission } = usePermission()
    const [centers, setCenter] = useState( isEdit ? {lat: parseFloat(isEdit?.latitude), lng: parseFloat(isEdit?.longitude)} : {lat: 0, lng: 0})
    const [zones, setZones] = useState(isEdit?.zone ? [isEdit?.zone] : [])

    console.log('c,z,a', isEdit)

    return (
        <>
            <Modal visible={visible} onPressOut={closeModal}>
                <div className="new-staff-container h-auto">
                    <div className="new-staff-header">
                        <p className="text-light py-2 ps-4 fs-5">
                            Alert Detail
                        </p>
                        <IconButton icon={cilX} className="me-3" onClick={closeModal}/>
                    </div>

                    <div className="py-3">
                        {isEdit?.message && (
                            <div className="p-2">
                                <p className="text-light fs-5 ms-3 d-inline-block">Alert Message: </p>
                                {isEdit?.alert_type == "Image" && (
                                    <>
                                        <img
                                            src={isEdit?.message}
                                            alt="image-2"
                                            width={80}
                                            height={80}
                                            className="d-block ms-3"
                                            style={{
                                                objectFit: 'cover',
                                            }}
                                        />
                                    </>
                                )}
                                {isEdit?.type == "Image" && (
                                    <>
                                        <img
                                            src={isEdit?.message}
                                            alt="image-2"
                                            width={80}
                                            height={80}
                                            className="d-block ms-3"
                                            style={{
                                                objectFit: 'cover',
                                            }}
                                        />
                                    </>
                                )}
                                {isEdit?.alert_type == "Voice" && (
                                    <>
                                        <audio
                                            controls
                                            src={isEdit?.message}
                                            className="d-block ms-3"
                                        />
                                    </>
                                )}
                                {isEdit?.type == "Voice" && (
                                    <>
                                        <audio
                                            controls
                                            src={isEdit?.message}
                                            className="d-block ms-3"
                                        />
                                    </>
                                )}
                                {isEdit?.alert_type == 'Message' && (
                                    <p className="text-color fs-5 ms-3 d-inline-block" style={{wordBreak:"break-all"}}>{isEdit?.message}</p>
                                )}
                                {isEdit?.type == 'Message' && (
                                    <p className="text-color fs-5 ms-3 d-inline-block">{isEdit?.message}</p>
                                )}

                            </div>
                        )}
                        <div className="d-flex p-2">
                            <p className="text-light fs-5 ms-3">User Name: </p>
                            <p className="text-color fs-5 ms-3">{isEdit?.user?.firstName} {''} {isEdit?.user?.lastName}</p>
                        </div>
                        <div className="d-flex p-2">
                            <p className="text-light fs-5 ms-3">User Email: </p>
                            <p className="text-color fs-5 ms-3">{isEdit?.user?.email}</p>
                        </div>
                        <div className="d-flex p-2">
                            <p className="text-light fs-5 ms-3">Alert Name: </p>
                            <p className="text-color fs-5 ms-3">{isEdit?.alert_name ? isEdit?.alert_name : isEdit?.alertType?.name}</p>
                        </div>
                        <div className="d-flex p-2">
                            <p className="text-light fs-5 ms-3">Facility Name: </p>
                            <p className="text-color fs-5 ms-3">{isEdit?.zone?.name ? isEdit?.zone?.name : isEdit?.alertType?.name}</p>
                        </div>
                        {centers && (
                            <div className="map-container rounded mx-2 mx-sm-4 p-1 mt-2" style={{height: 200}}>
                                <GoogleMap
                                    center={centers}
                                    zoom={15}
                                    zones={zones}
                                    zonesOrder={zones?.map((zone, index) => ({
                                        id: zone.id,
                                        index: zone?.users?.length,
                                    }))}
                                    pointerMap={true}
                                />
                            </div>
                        )}
                        {!isEdit?.isShowButton ? '' : (
                            <div className="py-2 d-flex align-items-center justify-content-between mx-4">
                                {checkPermission("Approved Alerts") && (
                                    <>
                                        {loadingApproved ? (
                                            <Spinner />
                                        ) : (
                                            <button
                                                className="btn btn-info"
                                                onClick={() => approved(isEdit?.id)}
                                            >
                                                Approved Alert
                                            </button>
                                        )}
                                    </>
                                )}
                                {checkPermission("Forward Alerts") && (
                                    <button
                                        className="btn btn-warning"
                                        onClick={() => {
                                            forward(isEdit)
                                            closeModal()
                                        }}
                                    >
                                        Forward Alert
                                    </button>
                                )}

                                {checkPermission("Cancel Alert") && (
                                    <>
                                        {loadingClose ? (
                                            <Spinner />
                                        ) : (
                                            <button
                                                className="btn btn-danger"
                                                onClick={closeAlert}
                                            >
                                                Cancel Alert
                                            </button>
                                        )}
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default AlertModal;