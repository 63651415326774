import * as React from "react";

//components
import {MenuHeader} from "../Atomics";
import {GroupInformation} from ".";

//services
import {GroupResponse} from "../../services/types";
import {CSpinner} from "@coreui/react";

interface Props {
    setGroupSelected: (group: GroupResponse) => void;
    groups: Array<GroupResponse>;
    refreshGroups: () => void;
    isLoadingGroups: boolean;
}

const GroupList: React.FC<Props> = ({
                                        setGroupSelected,
                                        groups,
                                        refreshGroups,
                                        isLoadingGroups
                                    }) => {
    return (
        <>
            <MenuHeader className="d-flex pt-3 pb-3">
                <p className="text-color ps-4 section-group-1">Group Name</p>
                <p className="text-color section-group-2">Participants</p>
                <p className="text-color section-group-3 ">Description</p>
            </MenuHeader>
            {isLoadingGroups ? (
                <div className="spinner">
                    <CSpinner variant="grow"/>
                </div>
            ) : (
                <>
                    {groups.length > 0 ? (
                        groups.map((item, index) => {
                            return (
                                <GroupInformation
                                    key={index}
                                    {...item}
                                    selectGroup={setGroupSelected}
                                    refreshGroups={refreshGroups}
                                />
                            );
                        })
                    ) : (
                        <div className="d-flex justify-content-center">
                            <p className="text-light fs-5 ms-5 me-2 pt-2">No groups created yet</p>
                        </div>
                    )}
                </>
            )}
        </>
    );
};
export default React.memo(GroupList);